(function () {
    'use strict';

    angular.module('Yelon').controller('PagesController', ['ApiFactory', 'spinnerService', '$location', '$rootScope', 'ngMeta', PagesController]);

    function PagesController(ApiFactory, spinnerService, $location, $rootScope, ngMeta) {
        let vm: any = this;
        vm.settings = [];
        vm.tabs = {
            'rolunk': 0,
            'segitoink': 1,
            'applikacio': 2,
        };
        vm.activeTab = vm.tabs[$location.hash()];

        vm.setActiveTab = function (hash) {
            $location.hash(hash);
        };

        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.settings = data;

            if (vm.settings.about_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.about_image).then(function (data) {
                    vm.settings.about_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }

            if (vm.settings.helpers_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.helpers_image).then(function (data) {
                    vm.settings.helpers_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }

            if (vm.settings.application_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.application_image).then(function (data) {
                    vm.settings.application_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            // duplicated, cause the original implementation doesn't trigger a page/state refresh when switching between
            // adult and non-adult state, and watching the rootscope variable would be overkill
            if (vm.settings.adult_about_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_about_image).then(function (data) {
                    vm.settings.adult_about_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }

            if (vm.settings.adult_helpers_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_helpers_image).then(function (data) {
                    vm.settings.adult_helpers_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }

            if (vm.settings.adult_application_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_application_image).then(function (data) {
                    vm.settings.adult_application_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
        }).finally(function () {
            spinnerService.decrementLoading();
        });
    }
})();
