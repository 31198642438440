(function () {
    'use strict';

    angular.module('Yelon').directive("ngMobileClick", [mobileClick]);

    function mobileClick() {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (event) {
                event.preventDefault();
                event.stopPropagation();

                scope.$apply(attrs["ngMobileClick"]);
            });
        };
    }
})();
