(function () {
    'use strict';

    angular.module('Yelon').controller('FaqController', ['ApiFactory', 'spinnerService', '$anchorScroll', '$rootScope', '$window', FaqController]);

    function FaqController(ApiFactory, spinnerService, $anchorScroll, $rootScope, $window) {
        let vm: any = this;
        vm.faqCategories = [];

        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            vm.faqCategories = data;

            let dict = data.filter((item) => item.title === "szótár")[0];

            if (dict && $rootScope.goToDict) {
                window.setTimeout(() => {
                    vm.onClickFaqTheme(dict.id);
                    delete $rootScope.goToDict;
                }, 10);
            }

        }).finally(() => spinnerService.decrementLoading());

        vm.onClickFaqTheme = function (id: number) {
            $anchorScroll('question-' + id);
        };

        vm.onClickQuestion = function (item: any) {
            item.opened = (item.opened === undefined) ? true : undefined;

            if (item.opened) {
                let category = vm.faqCategories.filter(cat => cat.id === item.faq_category_id)[0].title;

                if (category.toLowerCase() === 'szótár') {
                    $window.ga('send', 'event', 'Szótár', 'kattintás', item.question);
                } else {
                    $window.ga('send', 'event', 'GYIK', category, item.question);
                }
            }
        };
    }
})();
