(function () {
    'use strict';

    // Key codes
    var keys = {
        enter: 13,
        esc: 27,
        left: 37,
        right: 39
    };

    angular.module('Yelon').directive('imageGallery', ['$timeout', '$q', 'spinnerService', 'ApiFactory', '$state', '$rootScope', 'facebookService', 'SafeStorage',
        function ($timeout, $q, spinnerService, ApiFactory, $state, $rootScope, facebookService, storage) {
            return {
                replace: true,
                transclude: false,
                restrict: 'AE',
                scope: {
                    images: '=', // []
                    methods: '=?', // {}
                    sum: '=',
                    conf: '=?', // {}
                    thumbnails: '=?', // true|false
                    onOpen: '&?', // function
                    onClose: '&?' // function
                },
                templateUrl: '/app/layouts/imageGallery.html',

                link: function (scope, elem, attr) {

                    //scope.thumbnails = false
                    //scope.bubbles = false;
                    scope.isShowInfoBox = true;



                    /*
                     *	Operational functions
                     **/

                    // Show gallery loader
                    scope.showLoader = function () {
                        scope.imgLoading = true;
                    };

                    // Hide gallery loader
                    scope.hideLoader = function () {
                        scope.imgLoading = false;
                    };

                    // Image load complete promise
                    scope.loadImg = function (imgObj) {

                        // Return rejected promise
                        // if not image object received
                        if (!imgObj) {
                            return $q.reject();
                        }

                        var deferred = $q.defer();

                        // Show loder
                        if (!imgObj.hasOwnProperty('cached')) {
                            scope.showLoader();
                        }

                        // Process image
                        var img = new Image();
                        img.src = imgObj.url;
                        img.onload = function () {
                            // Hide loder
                            if (!imgObj.hasOwnProperty('cached')) {
                                scope.hideLoader();
                            }

                            // Cache image
                            if (!imgObj.hasOwnProperty('cached')) {

                            }
                            imgObj.cached = true;

                            return deferred.resolve(imgObj);
                        };

                        return deferred.promise;
                    };

                    scope.setActiveImg = function (imgObj) {
                        // Get images move direction
                        if (
                            scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) === (scope.images.length - 1) ||
                            (
                                scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) <= 0 &&
                                scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) !== -(scope.images.length - 1)
                            )

                        ) {
                            scope.imgMoveDirection = 'forward';
                        } else {
                            scope.imgMoveDirection = 'backward';
                        }

                        // Load image
                        scope.loadImg(imgObj).then(function (imgObj) {
                            scope.activeImg = imgObj;

                            if (storage.get('gallery_rated_' + scope.activeImg.id)) {
                                scope.voted = true;
                            }
                        });
                    };


                    /***************************************************/


                    /*
                     *	Gallery settings
                     **/

                    // Modify scope models
                    scope.images = (typeof scope.images !== "undefined") ? scope.images : [];
                    scope.methods = (typeof scope.methods !== "undefined") ? scope.methods : {};
                    scope.conf = (typeof scope.conf !== "undefined") ? scope.conf : {};


                    scope.onOpen = (typeof scope.onOpen !== "undefined") ? scope.onOpen : angular.noop;
                    scope.onClose = (typeof scope.onClose !== "undefined") ? scope.onClose : angular.noop;

                    // If images populate dynamically, reset gallery
                    var imagesFirstWatch = true;
                    scope.$watch('images', function () {
                        if (imagesFirstWatch) {
                            imagesFirstWatch = false;
                        } else if (scope.images.length) {
                            scope.setActiveImg(
                                scope.images[scope.activeImageIndex || 0]
                            );
                        }
                    });

                    // Watch index of visible/active image
                    // If index changes, make sure to load/change image
                    var activeImageIndexFirstWatch = true;
                    scope.$watch('activeImageIndex', function (newImgIndex) {
                        if (activeImageIndexFirstWatch) {
                            activeImageIndexFirstWatch = false;
                        } else if (scope.images.length) {
                            scope.setActiveImg(
                                scope.images[newImgIndex]
                            );
                        }
                    });
                    $rootScope.$watch('shareCount', function (newcount) {
                        scope.shareCount = newcount;
                    });
                    scope.sharePage = $rootScope.sharePage;

                    /***************************************************/


                    /*
                     *	Methods
                     **/

                    // Open gallery modal
                    scope.methods.open = function (imgIndex) {
                        // Open modal from an index if one passed
                        scope.activeImageIndex = (imgIndex) ? imgIndex : 0;

                        scope.opened = true;

                        // call open event after transition
                        $timeout(function () {
                            scope.isShowInfoBox = true;
                            scope.onOpen();

                            facebookService.update();
                        }, 300);
                    };

                    // Close gallery modal
                    scope.methods.close = function () {
                        scope.opened = false; // Model closed

                        // set overflow hidden to body
                        angular.element(document.body).removeClass('body-overflow-hidden');

                        // call close event after transition
                        $timeout(function () {
                            scope.onClose();
                            scope.activeImageIndex = 0; // Reset index
                            $state.go('^.articles');
                        }, 300);
                    };

                    // Change image to next
                    scope.methods.next = function () {

                        if (scope.activeImageIndex === (scope.images.length - 1)) {

                            if (!scope.isShowInfoBox) {
                                scope.isShowInfoBox = true;
                                return;
                            } else {
                                scope.isShowInfoBox = false;
                            }

                            scope.activeImageIndex = 0;
                        } else if (scope.activeImageIndex === 0 && scope.isShowInfoBox) {
                            scope.isShowInfoBox = false;
                        }
                        else {
                            scope.activeImageIndex = scope.activeImageIndex + 1;
                        }
                    };

                    // Change image to prev
                    scope.methods.prev = function () {
                        var imgLength = scope.images.length - 1;

                        if (scope.activeImageIndex === 0) {
                            if (scope.isShowInfoBox) {
                                scope.isShowInfoBox = false;
                                return;
                            }

                            scope.activeImageIndex = imgLength;
                        } else {

                            if (scope.activeImageIndex === imgLength && !scope.isShowInfoBox) {
                                scope.isShowInfoBox = true;
                                return;
                            } else if (scope.activeImageIndex === imgLength && scope.isShowInfoBox) {
                                scope.isShowInfoBox = false;
                            }

                            scope.activeImageIndex--;

                        }
                    };

                    // Close gallery on background click
                    scope.backgroundClose = function (e) {
                        var noCloseClasses = [
                            'galleria-image',
                            'destroy-icons-container',
                            'ext-url',
                            'close',
                            'next',
                            'prev',
                            'galleria-bubble'
                        ];

                        // check if clicked element has a class that
                        // belongs to `noCloseClasses`
                        for (var i = 0; i < e.target.classList.length; i++) {
                            if (noCloseClasses.indexOf(e.target.classList[i]) !== -1) {
                                break;
                            } else {
                                scope.methods.close();
                            }
                        }
                    };

                    scope.onClickVoteBtn = function (isUpvote: boolean) {
                        if (storage.get('gallery_rated_' + scope.activeImg.id)) {
                            return;
                        }
                        else {
                            spinnerService.incrementLoading();
                            ApiFactory.postFunction('articles/' + scope.activeImg.id + '/ratings', {
                                'like': isUpvote,
                                'type': 'galery'
                            }).then(() => {
                                storage.set('gallery_rated_' + scope.activeImg.id, true);
                                if (isUpvote) {
                                    scope.activeImg.ratings_up++;
                                } else {
                                    scope.activeImg.ratings_down++;
                                }
                                scope.voted = true;
                            }).finally(() => {
                                spinnerService.decrementLoading();
                            });
                        }
                    };

                    scope.getVotedClass = function () {
                        return (scope.voted) ? "voted" : "";
                    };

                    /***************************************************/


                    /*
                     *	User interactions
                     **/

                    // Key events
                    angular.element(document).bind('keyup', function (event) {


                        if (event.which === keys.right || event.which === keys.enter) {
                            $timeout(function () {
                                scope.methods.next();
                            });
                        } else if (event.which === keys.left) {
                            $timeout(function () {
                                scope.methods.prev();
                            });
                        } else if (event.which === keys.esc) {
                            $timeout(function () {
                                scope.methods.close();
                            });
                        }
                    });

                    // Swipe events
                    /*if(window.Hammer){
                     var hammerElem = new Hammer(elem[0]);
                     hammerElem.on('swiperight', function(ev){
                     $timeout(function(){
                     scope.methods.prev();
                     });
                     });
                     hammerElem.on('swipeleft', function(ev){
                     $timeout(function(){
                     scope.methods.next();
                     });
                     });
                     hammerElem.on('doubletap', function(ev){

                     $timeout(function(){
                     scope.methods.close();
                     });
                     });
                     }*/
                }
            };
        }
    ]);
})();
