(function () {
    'use strict';

    angular.module('Yelon').directive("tiles", tiles);

    function tiles() {
        return {
            restrict: "A",
            scope: {
                articles: '=',
                fixTiles: '=?',
                isEmbedded: '=?'
            },
            templateUrl: '/app/layouts/tiles.html',
            controller: ['$rootScope', '$scope', '$state', '$stateParams', '$uibModal', 'ApiFactory', 'ngMeta', 'Slug', '$anchorScroll', 'spinnerService', '$window', '$location', function ($rootScope, $scope, $state, $stateParams, $uibModal, ApiFactory, ngMeta, Slug, $anchorScroll, spinnerService, $window, $location) {
                $scope.flippedStatus = [];
                $scope.sum = 0;
                $scope.images = [];
                $scope.methods = {};

                $scope.fixTiles = typeof $scope.fixTiles === "undefined" ? [] : $scope.fixTiles;
                $scope.teasersDisabled = typeof $scope.teasersDisabled === "undefined" ? false : true;
                let artIdx = 0;
                let blockIdx = 0;
                $scope.blocks = [];
                let blocks = [];
                $scope.$watch("articles", (newVal, oldVal) => {
                    artIdx = 0;
                    blockIdx = 0;
                    $scope.blocks = [];
                    blocks = [];
                    while ((!$scope.isEmbedded && artIdx < $scope.articles.length) ||
                    $scope.isEmbedded && artIdx < $scope.articles.length && blockIdx < 3) {
                        blocks[blockIdx] = [
                            [null, null],
                            [null, null],
                            [null, null]
                        ];
                        //handle fix tiles here
                        if (Array.isArray($scope.fixTiles)) {
                            $scope.fixTiles.forEach(tile => {
                                if (tile.block === blockIdx) {
                                    blocks[blockIdx][tile.row][tile.col] = tile;
                                }
                            });
                        }
                        $scope.blocks[blockIdx] = [];
                        positionTiles(0, 0);
                        positionTiles(0, 1);
                        positionTiles(0, 2);
                        blockIdx++;
                    }

                    //check if gallery
                    if ($state.current.name === "home.gallery" || $state.current.name === "adult.gallery") {
                        let gallery,
                            articleId = Number($stateParams['id']);

                        for (let article of $scope.articles) {
                            if (article.id === articleId) {
                                gallery = article;
                                break;
                            }
                        }

                        if (gallery) {
                            $scope.openGallery(gallery);
                        }
                    }
                    else if ($state.current.name === 'home.articles' || $state.current.name === 'adult.articles') {
                        let checkSpinner = () => {
                            if (spinnerService.showSpinner) {
                                setTimeout(checkSpinner, 1000);
                            }
                            else if ($rootScope.tileAnchor !== null && $scope.articles.length > 0) {
                                $anchorScroll($rootScope.tileAnchor);

                                $rootScope.tileAnchor = null;
                            }
                        };
                        $scope.$evalAsync(() => setTimeout(checkSpinner, 1000));
                    }
                });

                function positionTiles(posX, posY) {
                    if (posY > 2 || posX > 5 || artIdx === $scope.articles.length) {
                        return;
                    }

                    let currCell = blocks[blockIdx][posY][posX];

                    if (blocks[blockIdx][posY][posX] === null || typeof currCell === "object") {
                        let isFixed = typeof currCell === "object" && currCell !== null;
                        let allowedTypes = $scope.isEmbedded ? ['tile2', 'tile4'] : ['tile1', 'tile2', 'tile3', 'tile4'];
                        if (posX % 2 === 1 || posX + 1 >= blocks[blockIdx][posY].length || blocks[blockIdx][posY][posX + 1] !== null) {
                            //noinspection TypeScriptValidateTypes
                            allowedTypes = allowedTypes.filter((type) => type !== 'tile2' && type !== 'tile4');
                        }
                        if (posY === 2) {
                            //noinspection TypeScriptValidateTypes
                            allowedTypes = allowedTypes.filter(type => type !== 'tile3' && type !== 'tile4');
                        }

                        let rnd = Math.random();
                        /**
                         * If all of the tiles can be used, the chances for each type to be chosen are the following:
                         * tile1: 15%
                         * tile2: 20%
                         * tile3: 20%
                         * tile4: 45%
                         */
                        let chosenIndex = 0;
                        if (allowedTypes.length === 4) {
                            chosenIndex = rnd < 0.15 ? 0 : (rnd < 0.35 ? 1 : (rnd < 0.55 ? 2 : 3));
                        } else {
                            chosenIndex = Math.floor(rnd * allowedTypes.length);
                        }
                        let chosenType = isFixed ? currCell.tileType : allowedTypes[chosenIndex];

                        if (isFixed) {
                            $scope.blocks[blockIdx].push(currCell);
                        } else {
                            //noinspection TypeScriptUnresolvedVariable
                            $scope.articles[artIdx].tileType = chosenType;
                            $scope.blocks[blockIdx].push($scope.articles[artIdx]);
                            artIdx++;
                            currCell = chosenType;
                        }
                        switch (chosenType) {
                            case 'tile2':
                                blocks[blockIdx][posY][posX + 1] = false;
                                break;
                            case 'tile3':
                                blocks[blockIdx][posY + 1][posX] = false;
                                break;
                            case 'tile4':
                                blocks[blockIdx][posY + 1][posX] = false;
                                blocks[blockIdx][posY + 1][posX + 1] = false;
                                blocks[blockIdx][posY][posX + 1] = false;
                                break;
                        }
                    }
                    positionTiles(posX + 1, posY);
                    return;
                }

                $scope.getAge = function(article) {
                    if (typeof article.attributes_values === "undefined") {
                        return;
                    }
                    let minAge = 100;
                    article.attributes_values.forEach(attr => {
                        if (attr.attribute_id === 2 && attr.title < minAge) {
                            minAge = attr.title;
                        }
                    });
                    return minAge;
                };

                $scope.getRowSpan = function (type) {
                    if (type === 'tile3' || type === 'tile4') {
                        return 2;
                    }
                    return 1;
                };

                $scope.getColSpan = function (type) {
                    if (type === 'tile2' || type === 'tile4') {
                        return 2;
                    }
                    return 1;
                };

                $scope.getHref = function (article) {
                    if (article.isFixed) {
                        return $state.href(article.route, article.routeParams);
                    } else if (article.type === 'motto') {
                        return '';
                    } else if (article.type === 'gallery') {
                        return $state.href("^.gallery", {
                            id: parseInt(article.id),
                            slug: Slug.slug(article.title)
                        });
                    } else if (article.type === 'quiz') {
                        return $state.href('^.quiz', {
                            id: article.id,
                            slug: Slug.slug(article.title)
                        });
                    } else if (article.type === 'video') {
                        return $state.href('^.video', {
                            id: article.id,
                            slug: Slug.slug(article.title)
                        });
                    } else {
                        return $state.href('^.article', {
                            id: article.id,
                            slug: Slug.slug(article.title)
                        });
                    }
                };

                $scope.followTile = function (article) {
                    $rootScope.tileAnchor = 'tile' + article.id;
                    if (article.isFixed) {
                        return $state.go(article.route, article.routeParams);
                    } else if (article.type === 'motto') {
                        return;
                    } else if (typeof article.popup_message !== 'string' || article.popup_message.length === 0) {
                        if (article.type === 'gallery') {
                            return $scope.openGallery(article);
                        } else if (article.type === 'quiz') {
                            return $state.go('^.quiz', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        } else if (article.type === 'video') {
                            return $state.go('^.video', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        }
                        return $state.go('^.article', {
                            id: article.id,
                            slug: Slug.slug(article.title)
                        });
                    } else {
                        let $parentScope = $scope;
                        $uibModal.open({
                            windowClass: "custom-modal",
                            templateUrl: '/app/layouts/popupMessage.html',
                            controller: ['$uibModalInstance', '$scope', '$state', 'article', ($uibModalInstance, $scope, $state, article) => {
                                $scope.article = article;
                                $scope.confirm = function () {
                                    if (article.type === 'gallery') {
                                        $parentScope.openGallery(article);
                                    } else if (article.type === 'quiz') {
                                        $state.go('^.quiz', {
                                            id: article.id,
                                            slug: Slug.slug(article.title)
                                        });
                                    } else if (article.type === 'video') {
                                        $state.go('^.video', {
                                            id: article.id,
                                            slug: Slug.slug(article.title)
                                        });
                                    } else {
                                        $state.go('^.article', {
                                            id: article.id,
                                            slug: Slug.slug(article.title)
                                        });
                                    }
                                    $uibModalInstance.close();
                                };
                                $scope.close = function () {
                                    $uibModalInstance.close();
                                };
                            }],
                            resolve: {
                                article: () => {
                                    return article;
                                }
                            }
                        });
                    }
                };

                $scope.getTileStyle = function (article): any {
                    if (article.isFixed) {
                        return {
                            'background': article.background,
                            'color': article.color
                        };
                    }
                    let tileImgUrl = [];
                    if (typeof article.cropped_images === "undefined" || article.cropped_images.length <= 1 ||
                        (tileImgUrl = article.cropped_images.filter(img => {
                            return img.type === article.tileType;
                        })).length === 0) {
                        return {
                            'background-color': article.color,
                            'background-image': '',
                            'background-size': 'cover',
                            'color': 'white'
                        };
                    }
                    return {
                        'background-color': article.color,
                        'background-image': 'url(' + tileImgUrl[0].url + ')',
                        'background-size': 'cover',
                        'color': 'black'
                    };
                };

                $scope.getBackTileStyle = function (article): any {
                    if (article.isFixed) {
                        return {
                            'background': typeof article.backTileBg !== "undefined" ? article.backTileBg : article.background,
                            'background-image': 'none',
                            'color': typeof article.backTileColor !== "undefined" ? article.backTileColor : article.color
                        };
                    }
                    return {
                        'background-color': article.theme_color,
                        'background-image': '',
                        'background-size': ''
                    };
                };

                $scope.openGallery = function (article) {
                    $scope.images = [];
                    ApiFactory.getFunction('articles/' + article.id).then(function (data) {
                        $scope.images = [];
                        angular.forEach(data.paragraphs, function (val, key) {
                            if (val.type === 'image') {
                                $state.go("^.gallery", {
                                    id: parseInt(article.id),
                                    slug: Slug.slug(article.title)
                                }, {
                                    notify: false
                                });
                                $scope.images.push({
                                    id: article.id,
                                    url: val.image.full_url,
                                    href: val.title,
                                    title: val.content,
                                    teaser: data.teaser,
                                    article_title: data.title,
                                    ratings_up: data.ratings_up,
                                    ratings_down: data.ratings_down
                                });
                            }
                        });
                        $scope.sum = $scope.images.length;
                        $scope.methods.open();

                        if (data.images.length > 0) {
                            let image = data.images[0];
                            ngMeta.setTag('og:image', image.full_url);
                            ngMeta.setTag('og:image:width', image.width);
                            ngMeta.setTag('og:image:height', image.height);
                        }

                        let ages = article.attributes_values.map(attr => {
                            if (attr.attribute_id === 2) {
                                return attr.title;
                            }
                        });
                        let themes = article.attributes_values.filter(attr => attr.attribute_id === 1).map(attr => attr.title);
                        let genders = article.attributes_values.filter(attr => attr.attribute_id === 3).map(attr => attr.title);
                        article.age = 18;
                        ages.forEach(age => {
                            if (typeof age !== "undefined" && age < article.age) {
                                article.age = age;
                            }
                        });

                        ngMeta.setTitle(data.title);
                        ngMeta.setTag('description', data.teaser);
                        //ngMeta.setTag('author', data.name);
                        ngMeta.setTag('og:title', data.title);
                        ngMeta.setTag('og:type', 'article');

                        $window.ga('set', 'page', $location.path());
                        $window.ga('send', 'pageview', {
                            'dimension1': article.main_type === 'adult' ? 'Szülőknek' : '10-18',
                            'dimension2': article.type,
                            'dimension3': article.age,
                            'dimension4': genders.join(),
                            'dimension5': themes.join()
                        });
                    });
                };
            }],
        };
    }
})();
