(function () {
    'use strict';

    angular.module('Yelon')
        .controller('ChatController', ['$interval', '$uibModal', 'socketFactory', 'ApiFactory', 'chatPrefix', 'SafeStorage', "$rootScope", "$sce", "$scope", ChatController]);

    function ChatController($interval, $uibModal, socketFactory, ApiFactory, chatPrefix, storage, $rootScope, $sce, $scope) {
        let vm: any = this;

        vm.chatOpened = false;
        vm.clientId = null;
        vm.session = null;
        vm.isOpenNow = false;
        vm.onlineHelpers = 0;
        vm.isEnded = false;
        vm.messages = [];
        vm.rating = 3;
        vm.isConnected = false;

        vm.keyPressed = keyPressed;
        vm.textPasted = textPasted;
        vm.inputChanged = inputChanged;
        vm.sendMessage = sendMessage;
        vm.rateConversation = rateConversation;
        vm.messageClicked = messageClicked;
        vm.closeConversation = closeConversation;
        vm.openEmojiPicker = openEmojiPicker;
        vm.onAddEmojiIcon = onAddEmojiIcon;
        vm.emojiIconText = ":smile:";
        vm.messageInput = "";
        vm.messageText = "";
        vm.onKeyDown = onKeyDown;
        vm.isEnableInput = isEnableInput;
        vm.isShowEmojiPicker = false;
        vm.picFileUrl = null;
        ApiFactory.getFunction('settings').then(parseBusinessHours);

        $interval(setIsOpen, 30000);

        let prevInput = "";

        let socket = socketFactory({
            prefix: '',
            ioSocket: io.connect(chatPrefix)
        });

        socket.on('connect', initChat);

        function initChat() {
            vm.isConnected = true;
            vm.chatToken = storage.get('chatToken');

            if (vm.chatToken === null) {
                socket.emit('token:generate');
            } else {
                socket.emit('token:validate', vm.chatToken);
            }

            socket.on('token:generated', saveUser);
            socket.on('token:validated', saveUser);
            socket.on('chat:clients', data => {
                vm.onlineHelpers = data.count.helpers;
            });
            socket.on('disconnect', () => {
                vm.isConnected = false;
                vm.clientId = null;
                vm.chatToken = null;
                vm.messages = [];
                socket.removeAllListeners();
                socket.on('connect', initChat);
            });

            socket.on('chat:start', chatStarted);

            socket.on('chat:message', messageReceived);

            socket.on('chat:typing', data => {
                if (data.client.id !== vm.clientId) {
                    vm.isTypingReceived = data.typing;
                }
            });

            socket.on('chat:end', data => {
                /*vm.messages.push({
                    client: 'sessionclosed',
                    message: "Értékeléshez kérlek kattints erre az üzenetre",
                    time: new Date()
                });*/
                vm.isEnded = true;
                //delete vm.session;
                vm.isTypingReceived = false;
            });

            socket.on('chat:archive', archiveConversation);
            socket.on('chat:announcement', data => {
                vm.messages.push({
                    client: 'system',
                    message: data.message,
                    time: new Date()
                });
            });
        }

        function saveUser(data) {
            vm.clientId = data.id;
            vm.chatToken = data.token;
            storage.set('chatToken', data.token);
            vm.isConnected = true;
        }

        function onKeyDown($event) {
            if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter" || $event.keyIdentifier === "Enter") && $event.ctrlKey === false && $event.shiftKey === false) {
                $event.preventDefault();

                this.isEnterKey = true;
            }
        }

        function keyPressed($event) {
            this.textareaInput = $event.target;

            if (this.isEnterKey) {
                $event.preventDefault();
                this.sendMessage();
                vm.messageText = "";
                $event.target.innerHTML = "";
                delete this.isEnterKey;
            } else {
                let replaceText = $event.target.innerHTML.replace(/(<img src=".[\/a-z0-9\."\s=]+)(:[a-z_]+:)(">)/g, '$2');

                replaceText = replaceText.replace(/<br>/g, "\n");
                replaceText = replaceText.replace(/&nbsp;/g, "");
                replaceText = replaceText.replace(/&lt;/g, "<");
                replaceText = replaceText.replace(/&gt;/g, ">");

                vm.messageInput = replaceText;
                vm.inputChanged();
            }
        }

        function textPasted($event) {
            $event.preventDefault();

            document.execCommand("insertHTML", false, $event.clipboardData.getData('text/plain'));
        }

        function inputChanged() {
            //handle typing here
            if (prevInput.trim().length > 0 && vm.messageInput.trim().length === 0) {
                emitTyping(false);
            } else if (prevInput.trim().length === 0 && vm.messageInput.trim().length > 0) {
                emitTyping(true);
            }
            prevInput = vm.messageInput;
        }

        function sendMessage() {
            if (vm.messageInput.trim().length > 0) {
                if (vm.session === null) {
                    socket.emit('chat:create', vm.messageInput);
                } else {
                    socket.emit('chat:message', vm.session.id, vm.messageInput);
                }
                if (this.textareaInput) {
                    this.textareaInput.innerHTML = "";
                }
                vm.messageText = "";
                vm.messageInput = "";
                prevInput = "";
                emitTyping(false);
            }
        }

        function closeConversation() {
            $uibModal.open({
                windowClass: "custom-modal",
                templateUrl: '/app/layouts/popupMessage.html',
                controller: ['$uibModalInstance', '$scope', 'article', ($uibModalInstance, $scope, article) => {
                    $scope.article = article;
                    $scope.confirm = function () {
                        socket.emit('chat:close', vm.session.id);
                        $uibModalInstance.close();
                    };
                    $scope.close = function () {
                        $uibModalInstance.close();
                    };
                }],
                resolve: {
                    article: () => {
                        return {
                            popup_message: 'Biztosan befejezed a beszélgetést? Ezzel a beszélgetés törlődik.'
                        };
                    }
                }
            });
        }

        function rateConversation() {
            if (vm.session !== null) {
                socket.emit('chat:rating', vm.session.id, vm.rating);
                delete vm.session;
            }
        }

        function chatStarted(data) {
            vm.session = data;
            vm.session.id = data.session_id;
            vm.session.messages.forEach(msg => {
                vm.messages.push({
                    client: msg.client,
                    message: msg.message,
                    time: new Date(msg.time)
                });
            });
            $rootScope.isChatOpened = true;
            vm.chatOpened = true;
        }

        function messageClicked(msg: any) {
            msg.showTime = !msg.showTime;
            if (msg.client === 'sessionclosed') {
                vm.isEnded = true;
            }
        }

        function archiveConversation(data) {
            vm.session = null;
            vm.messageInput = "";
            $rootScope.isChatOpened = false;
            vm.chatOpened = false;
            vm.isEnded = false;
            vm.messages = [];
            vm.isTypingReceived = false;
        }

        function messageReceived(data) {
            if (vm.session !== null) {
                vm.messages.push({
                    client: data.client,
                    message: $sce.trustAsHtml(data.message),
                    time: new Date()
                });
                $rootScope.countMessage++;
            }
        }

        function emitTyping(val: boolean) {
            if (vm.session !== null) {
                socket.emit('chat:typing', vm.session.id, val);
            }
        }


        function parseBusinessHours(data) {
            vm.dayNames = [
                "Hétfő",
                "Kedd",
                "Szerda",
                "Csütörtök",
                "Péntek",
                "Szombat",
                "Vasárnap"
            ];
            vm.businessHours = data.business_hours;
            let sunday = vm.businessHours.shift();
            vm.businessHours.push(sunday);

            if (data.closed_image !== null) {
                ApiFactory.getFunction('images/' + data.closed_image).then(function (result) {
                    vm.picFileUrl = result.full_url;
                });
            }

            setIsOpen();
        }

        function setIsOpen() {
            vm.now = new Date();
            let today = vm.businessHours[(vm.now.getDay() + 6) % 7];
            let fromTime = today.from.split(':');
            let toTime = today.to.split(':');
            let from = new Date().setHours(fromTime[0], fromTime[1]);
            let to = new Date().setHours(toTime[0], toTime[1]);
            vm.isOpenNow = vm.now >= from && vm.now <= to;
        }

        function openEmojiPicker() {
            vm.isShowEmojiPicker = (!vm.isShowEmojiPicker);
        }

        function onAddEmojiIcon(text: string) {
            let searchName = text.replace(/[:]/g, "");
            let selectedEmojiObj = $rootScope.emojis[searchName];
            let imgHtml = $sce.trustAsHtml(`<img src="./assets/img/emojis/${selectedEmojiObj.unicode}.svg" alt="${text}">`);

            vm.messageText = vm.messageInput;

            if (vm.messageText !== "") {
                vm.messageText = vm.messageText.replace(/([:][a-z_]+[:])/g, function (...texts) {
                    searchName = texts[0].replace(/[:]/g, "");
                    selectedEmojiObj = $rootScope.emojis[searchName];
                    texts[1] = $sce.trustAsHtml(`<img src="./assets/img/emojis/${selectedEmojiObj.unicode}.svg" alt="${texts[0]}">`);
                    return texts[1];
                });

                vm.messageText = vm.messageText.replace(/<br>/g, "\n");
            }

            vm.messageInput += text + ' ';
            vm.messageText += imgHtml;

            vm.isShowEmojiPicker = false;
        }

        function isEnableInput() {
            return (!((vm.session === null && !vm.isOpenNow) || !vm.isConnected));
        }
    }

})();
