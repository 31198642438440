(function () {
    'use strict';

    angular.module('Yelon').factory('ApiFactory', ['$http', '$q', 'apiPrefix', '$rootScope', ApiFactory]);

    function ApiFactory($http: angular.IHttpService, $q: angular.IQService, apiPrefix, $rootScope) {
        return {
            getFunction: getFunction,
            postFunction: postFunction,
            patchFunction: patchFunction,
            deleteFunction: deleteFunction,
        };

        function getFunction(url: string) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }

            return $http.get(apiPrefix + url).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function postFunction(url: string, data: any, settings: any) {
            return $http.post(apiPrefix + url, data, settings).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function patchFunction(url: string, data: any, settings: any) {
            return $http.patch(apiPrefix + url, data, settings).then(success).catch(returnError);

            function success() {
                $rootScope.lastSave = new Date();
                return true;
            }

            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen módosítás, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function deleteFunction(url: string) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }

            return $http.delete(apiPrefix + url).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen törlés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        /**
         *
         * @param response
         * @returns {boolean} should the caller show the alert window?
         */
        function handleError(response) {
            if (response.status === 500) {
                return true;
            }

            //expired token
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    console.error('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }

            //other message
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }

            return true;
        }
    }
})();
