(function () {
    'use strict';

    angular.module('Yelon').filter('toTrusted', ['$sce', toTrusted]);

    function toTrusted($sce) {
        return function(text) {
            return $sce.trustAsHtml(text);
        };
    }

})();
