(function () {
    'use strict';

    angular.module('Yelon')
        .service('Slug', ['$window', ($window) => {
            $window.slug.defaults.mode = 'rfc3986';

            return {
                slug: (value) => $window.slug(value)
            };
        }]);
})();
