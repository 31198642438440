declare var emojione:any;

(function () {
    'use strict';

    angular.module('Yelon')
        .filter('slugify', ['Slug', (Slug) => {
            return (input) => {
                if (typeof input !== 'string' || input.length === 0) {
                    return input;
                }

                return Slug.slug(input);
            };
        }]);
})();
