(function () {
    'use strict';

    /**
     * Fix for Safari not supporting localStorage in private browsing mode
     *
     * @license MIT
     * @link https://github.com/HackedByChinese/ng-idle/issues/111
     */
    angular.module('Yelon')
        .service('SafeStorage', ['$window', '$cookieStore', function ($window, $cookieStore) {
            let storage, methods;

            function fromJson(data: any) {
                try {
                    return angular.fromJson(data);
                } catch (err) {
                    return null;
                }
            }

            try {
                storage = $window.localStorage;

                methods = {
                    driver: 'localstorage',

                    set: (key, value) => storage.setItem(key, angular.toJson(value)),
                    get: (key) => fromJson(storage.getItem(key)),
                    remove: (key) => storage.removeItem(key),

                    // backwards compatibility with localStorage API
                    setItem: (key, value) => storage.setItem(key, value),
                    getItem: (key) => storage.getItem(key),
                    removeItem: (key) => storage.removeItem(key)
                };
            } catch (err) {
                methods = {
                    driver: 'cookie',

                    set: (key, value) => $cookieStore.put(key, angular.toJson(value)),
                    get: (key) => fromJson($cookieStore.get(key)),
                    remove: (key) => $cookieStore.remove(key),

                    // backwards compatibility with localStorage API
                    setItem: (key, value) => $cookieStore.put(key, value),
                    getItem: (key) => $cookieStore.get(key),
                    removeItem: (key) => $cookieStore.remove(key)
                };
            }

            return methods;
        }]);
})();
