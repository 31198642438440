(function () {
    'use strict';

    angular.module('Yelon').controller('SearchController', ['ApiFactory', 'spinnerService', '$timeout', '$rootScope',"$uibModal", '$window', SearchController]);

    function SearchController(ApiFactory, spinnerService, $timeout, $rootScope, $uibModal, $window) {
        let vm = this;

        vm.queryStr = "";
        vm.searchTimeout = null;
        vm.isSearchVisible = false;

        vm.queryChanged = queryChanged;
        vm.onClickResultItem = onClickResultItem;

        function queryChanged() {
            let mainType = $rootScope.adult ? "adult" : "web";
            if (vm.searchTimeout) {
                $timeout.cancel(vm.searchTimeout);
            }
            vm.searchTimeout = $timeout(() => {
                if (vm.queryStr.trim().length > 0) {
                    $window.ga('send', 'event', 'Keresés', 'Keresés', vm.queryStr);

                    ApiFactory.getFunction('search?main_type=' + mainType + '&q=' + vm.queryStr).then(data => {
                        if (!vm.isSearchVisible) {
                            vm.isSearchVisible = true;
                        }
                        vm.results = data;
                    });
                }
            }, 2000);
        }

        function onClickResultItem(event, result) {
            if (result.popup_message) {
                event.preventDefault();
                openModal(result);
            }
        };

        function openModal(data) {
            $uibModal.open({
                windowClass: "custom-modal",
                templateUrl: '/app/layouts/popupMessage.html',
                controller: ['$uibModalInstance', '$scope', '$state', 'article', ($uibModalInstance, $scope, $state, article) => {
                    $scope.article = article;
                    $scope.confirm = function () {
                        window.location.href = article.url;
                    };
                    $scope.close = function () {
                        $uibModalInstance.close();
                    };
                }],
                resolve: {
                    article: () => {
                        return data;
                    }
                }
            });
        };

        $rootScope.$watch("selectedTagItemTitle", function (newValue) {
            if (newValue !== undefined) {
                vm.queryStr = newValue;
                vm.queryChanged();
            }
        });

        $rootScope.$watch("isSearchVisible", function (newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                vm.queryStr = "";

                delete $rootScope.selectedTagItemTitle;
                delete vm.results;
            }
        });

    }
})();
