(function () {
    'use strict';

    angular.module('Yelon').filter('youtubeEmbedUrl', ['$sce', youtubeEmbedUrl]);

    function youtubeEmbedUrl ($sce) {
        return function(videoId) {
            return $sce.trustAsResourceUrl('//www.youtube.com/embed/' + videoId);
        };
    }

})();
