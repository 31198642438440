/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';

    angular.module('Yelon').config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider) {
            $stateProvider
                .state('home', {
                    abstract: true,
                    url: '/',
                    controller: ['$rootScope', 'isAdult', ($rootScope: angular.IRootScopeService, isAdult: boolean) => {
                        $rootScope['adult'] = isAdult;
                    }],
                    resolve: {
                        isAdult: () => {
                            return false;
                        }
                    },
                    template: `<div class="row"><div data-ui-view class="col-xs-12 content"></div></div>`
                })
                .state('adult', {
                    abstract: true,
                    url: '/szuloknek',
                    controller: ['$rootScope', 'isAdult', ($rootScope: angular.IRootScopeService, isAdult: boolean) => {
                        $rootScope['adult'] = isAdult;
                    }],
                    resolve: {
                        isAdult: () => {
                            return true;
                        }
                    },
                    template: `<div class="row"><div data-ui-view class="col-xs-12 content"></div></div>`
                })
                .state('home.articles', {
                    templateUrl: 'app/Home/index.html',
                    url: '',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "home-page",
                        pageClass: ""
                    },
                    meta: {
                        title: 'Yelon',
                        titleSuffix: '',
                        'og:title': 'Yelon'
                    }
                })
                .state('adult.articles', {
                    templateUrl: 'app/Home/index.html',
                    url: '',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "home-page",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Szülőknek',
                        'og:title': 'Szülőknek'
                    }
                })
                .state('home.gallery', {
                    url: 'galeria/{id}/{slug}',
                    templateUrl: 'app/Home/index.html',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "home-page",
                        pageClass: ""
                    },
                    meta: {
                        title: 'Galéria',
                        'og:title': 'Galéria'
                    }
                })
                .state('adult.gallery', {
                    url: '/galeria/{id}/{slug}',
                    templateUrl: 'app/Home/index.html',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "home-page",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Galéria',
                        'og:title': 'Galéria'
                    }
                })
                .state('home.tag', {
                    url: 'cimke/{tag}',
                    templateUrl: 'app/Home/index.html',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "tag-page"
                    },
                    meta: {
                        title: 'Címke',
                        'og:title': 'Címke'
                    }
                })
                .state('adult.tag', {
                    url: '/cimke/{tag}',
                    templateUrl: 'app/Home/index.html',
                    controller: 'HomeController as homeCtrl',
                    data: {
                        pageId: "tag-page"
                    },
                    meta: {
                        title: 'Címke',
                        'og:title': 'Címke'
                    }
                })
                .state('home.faq', {
                    url: 'gyakori-kerdesek',
                    templateUrl: 'app/Faq/index.html',
                    controller: 'FaqController as faqCtrl',
                    data: {
                        pageId: "faq-page"
                    },
                    meta: {
                        title: 'Gyakori kérdések',
                        'og:title': 'Gyakori kérdések',
                        description: 'Lehet, hogy már más is feltette ugyanezt a kérdést? Nézd meg, melyek a leggyakoribb kérdések a chaten!'
                    }
                })
                .state('home.pages', {
                    url: 'oldalak',
                    templateUrl: 'app/Pages/index.html',
                    controller: 'PagesController as pagesCtrl',
                    data: {
                        pageId: "fix-pages"
                    },
                    meta: {
                        title: 'Rólunk',
                        'og:title': 'Rólunk'
                    }
                })
                .state('adult.pages', {
                    url: '/oldalak',
                    templateUrl: 'app/Pages/index.html',
                    controller: 'PagesController as pagesCtrl',
                    data: {
                        pageId: "fix-pages",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Rólunk',
                        'og:title': 'Rólunk'
                    }
                })
                .state('home.article', {
                    url: 'cikkek/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: ""
                    },
                    meta: {
                        title: 'Cikk',
                        'og:title': 'Cikk'
                    }
                })
                .state('adult.article', {
                    url: '/cikkek/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Cikk',
                        'og:title': 'Cikk'
                    }
                })
                .state('home.quiz', {
                    url: 'kviz/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: ""
                    },
                    meta: {
                        title: 'Kvíz',
                        'og:title': 'Kvíz'
                    }
                })
                .state('adult.quiz', {
                    url: '/kviz/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Kvíz',
                        'og:title': 'Kvíz'
                    }
                })
                .state('home.video', {
                    url: 'video/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: ""
                    },
                    meta: {
                        title: 'Videó',
                        'og:title': 'Videó'
                    }
                })
                .state('adult.video', {
                    url: '/video/{id}/{slug}',
                    templateUrl: 'app/Article/display.html',
                    controller: 'ArticleController as articleCtrl',
                    data: {
                        pageId: "article-page",
                        pageClass: "adult"
                    },
                    meta: {
                        title: 'Videó',
                        'og:title': 'Videó'
                    }
                })
                .state('otherwise', {
                    url: "*path",
                    template: 'Ez az oldal nem létezik.',
                    controller: [
                        '$state', '$scope', '$location',
                        function ($state: angular.ui.IStateService, $scope: angular.IScope, $location) {
                            if ($location.path() === '/szülőknek') {
                                $state.go('adult.articles');
                            }
                            $scope['goHome'] = function () {
                                $state.go('home.articles');
                            };
                        }
                    ],
                    meta: {
                        title: 'Nem létező oldal',
                        'og:title': 'Nem létező oldal'
                    }
                });

            $locationProvider.html5Mode(true);
        }
    ]);

})();
