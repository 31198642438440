/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';
    angular.module('Yelon').run(['$rootScope', '$state', 'ApiFactory', 'ngMeta', '$window', '$location', 'gaTrackingId', '$http', 'fbAppId', function ($rootScope, $state, ApiFactory, ngMeta, $window, $location, gaTrackingId, $http, fbAppId) {
            var defaultTitle;
            var notifyInterval;
            $rootScope.tileAnchor = null;
            $rootScope.countMessage = 0;
            window.onblur = function () {
                $rootScope.countMessage = 0;
                if (!defaultTitle) {
                    defaultTitle = document.title;
                }
                notifyInterval = window.setInterval(function () {
                    if ($rootScope.countMessage > 0) {
                        document.title = "(" + $rootScope.countMessage + ") " + defaultTitle;
                    }
                }, 1000);
            };
            window.onfocus = function () {
                if (defaultTitle) {
                    document.title = defaultTitle;
                    window.clearInterval(notifyInterval);
                    notifyInterval = undefined;
                    $rootScope.countMessage = 0;
                }
            };
            $http.get("./app/emoji_strategy.json").then(function (result) {
                $rootScope.emojis = result.data;
            }).catch(function () {
                console.warn("Hiányzik az emoji_strategy.json file");
            });
            $rootScope.menuItemActiveClass = "";
            $rootScope['isMenuVisible'] = false;
            $rootScope['cookieConsentJustAgreed'] = false;
            $rootScope['adult'] = false;
            $rootScope['filters'] = [[], [], []];
            $rootScope['shouldResetFilters'] = true;
            $rootScope.isShowAppDownloaderContent = true;
            $rootScope['selectedAttributes'] = [];
            $rootScope['selectedFilterNames'] = { 'theme': [], 'age': [], 'gender': [] };
            $rootScope['filterCounter'] = { theme: 0, age: 0, gender: 0 };
            $rootScope['toggleFilter'] = function (attrName, attrVal) {
                var idx = $rootScope.selectedAttributes.indexOf(attrVal.id);
                if (!attrVal.activeClass) {
                    attrVal.activeClass = "active";
                }
                else {
                    delete attrVal.activeClass;
                }
                if (idx > -1) {
                    $rootScope.filterCounter[attrName]--;
                    $rootScope.selectedAttributes.splice(idx, 1);
                    if (attrName !== "age") {
                        $rootScope.selectedFilterNames[attrName].splice($rootScope.selectedFilterNames[attrName].indexOf(attrVal.title), 1);
                    }
                    else {
                        $rootScope.selectedFilterNames.age.forEach(function (item, index) {
                            if (attrVal.title === item) {
                                $rootScope.selectedFilterNames.age[index] = null;
                            }
                        });
                    }
                }
                else {
                    $rootScope.filterCounter[attrName]++;
                    var firstValue_1 = Number($rootScope.selectedAttributes[0]);
                    var lastValue_1 = Number(attrVal.id);
                    console.log(firstValue_1);
                    console.log(lastValue_1);
                    if (firstValue_1 > lastValue_1) {
                        firstValue_1 = lastValue_1;
                        lastValue_1 = Number($rootScope.selectedAttributes[$rootScope.selectedAttributes.length - 1]);
                    }
                    if (attrName === "age" && firstValue_1 !== undefined && firstValue_1 < lastValue_1) {
                        var arrayMiddle_1 = [];
                        var titles_1 = [];
                        $rootScope.filters[1].forEach(function (item) {
                            if (item.id >= firstValue_1 && item.id <= lastValue_1) {
                                arrayMiddle_1.push(item.id);
                                titles_1.push(item.title);
                            }
                        });
                        $rootScope.selectedAttributes = arrayMiddle_1;
                        $rootScope.selectedFilterNames[attrName] = titles_1;
                    }
                    else {
                        $rootScope.selectedFilterNames[attrName].push(attrVal.title);
                        $rootScope.selectedAttributes.push(attrVal.id);
                    }
                }
            };
            $rootScope['resetFilters'] = function () {
                $rootScope.filters.forEach(function (filter) {
                    filter.forEach(function (item) {
                        if (item.activeClass) {
                            delete item.activeClass;
                        }
                    });
                });
                for (var i in $rootScope.selectedFilters) {
                    if ($rootScope.selectedFilters.hasOwnProperty(i)) {
                        $rootScope.selectedFilters[i] = [];
                    }
                }
                $rootScope.selectedAttributes = [];
            };
            ApiFactory.getFunction('attributes').then(function (data) {
                $rootScope.themes = data[0];
                $rootScope.filters[0] = data[0] ? data[0].attributes_values : [];
                $rootScope.filters[1] = data[1] ? data[1].attributes_values : [];
                $rootScope.filters[2] = data[2] ? data[2].attributes_values : [];
                $rootScope.filters[3] = data[3] ? data[3].attributes_values : [];
            });
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if ((toState.name.substr(0, 4) === 'home' && fromState.name.substr(0, 4) !== 'home') ||
                    (toState.name.substr(0, 5) === 'adult' && fromState.name.substr(0, 5) !== 'adult')) {
                    $rootScope.shouldResetFilters = true;
                }
                if (toState && toState.data) {
                    $rootScope.pageId = toState.data.pageId;
                    $rootScope.pageClass = toState.data.pageClass || '';
                    if ($rootScope.pageId === "home-page" && $rootScope.shouldResetFilters) {
                        if ($rootScope.selectedAttributes.length > 0) {
                            $rootScope.filterCounter = { theme: 0, age: 0, gender: 0 };
                            $rootScope.selectedAttributes = [];
                            $rootScope.selectedFilterNames = {
                                'theme': [],
                                'age': [],
                                'gender': []
                            };
                        }
                        $rootScope.shouldResetFilters = false;
                    }
                }
                $rootScope.menuItemActiveClass = "";
                $rootScope.isMenuVisible = false;
                $rootScope.isSearchVisible = false;
            });
            $rootScope.$on('$stateChangeSuccess', function () {
                ngMeta.init();
                var url = $state.href($state.current.name, $state.params, { absolute: true });
                ngMeta.setTag('url', url);
                $window.ga('set', 'page', $location.path());
                console.log($state.current.templateUrl);
                if ($state.current.templateUrl !== "app/Article/display.html") {
                    console.log('GA pageview sent');
                    $window.ga('send', 'pageview');
                }
            });
            $rootScope.onClickDictBtn = function (event) {
                event.preventDefault();
                $rootScope.goToDict = true;
                $state.go("home.faq", null, { reload: true });
            };
            /*$rootScope.$watch("adult", function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    switch (newValue) {
                        case true:
                            $state.go("adult");
                            break;
                        default:
                            $state.go("home");
                            break;
                    }
                }
            });*/
            $rootScope.getFilteredAgeTitle = function () {
                var length = $rootScope.selectedFilterNames.age.length;
                if (length === 0) {
                    return "Kor";
                }
                else if (length === 1) {
                    return $rootScope.selectedFilterNames.age[0];
                }
                else {
                    var filter = $rootScope.selectedFilterNames.age.filter(function (item) { return item !== null; });
                    if (filter.length === 1) {
                        return filter[0];
                    }
                    else if (filter.length > 1) {
                        var firstParts_1 = [];
                        var lastParts_1 = [];
                        var result = void 0;
                        var isFirstPart_1 = true;
                        $rootScope.selectedFilterNames.age.forEach(function (item, index, array) {
                            if (item === null && isFirstPart_1) {
                                isFirstPart_1 = false;
                            }
                            else if (item !== null && isFirstPart_1) {
                                firstParts_1.push(item);
                            }
                            else if (item !== null && !isFirstPart_1) {
                                lastParts_1.push(item);
                            }
                        });
                        if (firstParts_1.length === 0 && lastParts_1.length === 0) {
                            result = "Kor";
                        }
                        else if (firstParts_1.length === 0) {
                            var first = lastParts_1[0];
                            var last = lastParts_1[lastParts_1.length - 1];
                            result = first + "-" + last;
                        }
                        else if (lastParts_1.length === 0) {
                            var first = firstParts_1[0];
                            var last = firstParts_1[firstParts_1.length - 1];
                            result = first + "-" + last;
                        }
                        else {
                            if (firstParts_1.length === 1 && lastParts_1.length === 1) {
                                result = firstParts_1[0] + "," + lastParts_1[0];
                            }
                            else if (firstParts_1.length === 1) {
                                result = firstParts_1[0] + "," + lastParts_1[0] + "-" + lastParts_1[lastParts_1.length - 1];
                            }
                            else if (lastParts_1.length === 1) {
                                result = firstParts_1[0] + "-" + firstParts_1[firstParts_1.length - 1] + "," + lastParts_1[0];
                            }
                            else {
                                result = firstParts_1[0] + "-" + firstParts_1[firstParts_1.length - 1] + "," + lastParts_1[0] + "-" + lastParts_1[lastParts_1.length - 1];
                            }
                        }
                        return result;
                    }
                    else {
                        return "Kor";
                    }
                }
            };
            $rootScope.sharePage = function () {
                if (typeof FB !== 'undefined') {
                    FB.ui({
                        app_id: fbAppId,
                        method: 'share',
                        href: $state.href($state.current.name, $state.params, { absolute: true })
                    });
                }
            };
            $rootScope.isShowSiteHelp = function (notAgreeYet) {
                var isShow = ($rootScope.cookieConsentJustAgreed && notAgreeYet) ? true : false;
                if (isShow) {
                    $rootScope.reflectorAnimateClass = "reflector-animate";
                }
                else {
                    delete $rootScope.reflectorAnimateClass;
                }
                return isShow;
            };
            $rootScope.onClickAppDownloaderClose = function () {
                $rootScope.isShowAppDownloaderContent = false;
            };
            $rootScope.reloadPage = function () {
                if ($state.current.name === 'home.articles' || $state.current.name === 'adult.articles') {
                    $rootScope.shouldResetFilters = true;
                    $state.reload();
                }
            };
            $rootScope.shareCount = 0;
            ngMeta.init();
            $window.ga('create', gaTrackingId, 'auto');
            var fbInited = false;
            $window.fbAsyncInit = function () {
                if (!fbInited && typeof FB !== 'undefined') {
                    FB.init({
                        appId: fbAppId,
                        status: true,
                        cookie: true,
                        xfbml: true,
                        version: 'v2.8'
                    });
                    fbInited = true;
                }
                else {
                    setTimeout($window.fbAsyncInit, 2500);
                }
            };
            $window.fbAsyncInit();
        }]);
    angular.module('Yelon').config(['$mdIconProvider', function ($mdIconProvider) {
            $mdIconProvider.iconSet("avatar", 'icons/avatar-icons.svg', 128);
        }]);
    angular.module('Yelon').config(['markedProvider', function (markedProvider) {
            markedProvider.setOptions({ gfm: true });
            markedProvider.setRenderer({
                link: function (href, title, text) {
                    return '<a href="' + href + '"' + (title ? ' title="' + title + '"' : '') + ' target="_blank">' + text + '</a>';
                },
                table: function (header, body) {
                    return '<table class="table table-striped"><thead>' + header + '</thead><tbody>' + body + '</tbody></table>';
                }
            });
        }]);
    angular.module('Yelon').config(['fbAppId', 'ogImage', 'ogImageWidth', 'ogImageHeight', 'ngMetaProvider', function (fbAppId, ogImage, ogImageWidth, ogImageHeight, ngMetaProvider) {
            ngMetaProvider.useTitleSuffix(true);
            ngMetaProvider.setDefaultTitle('Főoldal');
            ngMetaProvider.setDefaultTitleSuffix(' - Yelon');
            ngMetaProvider.setDefaultTag('description', 'A Yelon szexedukációs weboldal és alkalmazás azért jött létre, hogy testről, lélekről és szexualitásról őszintén, tabuk nélkül tájékozódhass.');
            ngMetaProvider.setDefaultTag('og:title', 'Főoldal - Yelon');
            ngMetaProvider.setDefaultTag('og:description', 'A Yelon szexedukációs weboldal és alkalmazás azért jött létre, hogy testről, lélekről és szexualitásról őszintén, tabuk nélkül tájékozódhass.');
            ngMetaProvider.setDefaultTag('author', 'Yelon');
            ngMetaProvider.setDefaultTag('fb:app_id', fbAppId);
            ngMetaProvider.setDefaultTag('og:locale', 'hu_HU');
            ngMetaProvider.setDefaultTag('og:site_name', 'Yelon');
            ngMetaProvider.setDefaultTag('og:type', 'website');
            ngMetaProvider.setDefaultTag('og:image', ogImage);
            ngMetaProvider.setDefaultTag('og:image:width', ogImageWidth);
            ngMetaProvider.setDefaultTag('og:image:height', ogImageHeight);
        }]);
})();
/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';
    angular.module('Yelon').config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider) {
            $stateProvider
                .state('home', {
                abstract: true,
                url: '/',
                controller: ['$rootScope', 'isAdult', function ($rootScope, isAdult) {
                        $rootScope['adult'] = isAdult;
                    }],
                resolve: {
                    isAdult: function () {
                        return false;
                    }
                },
                template: "<div class=\"row\"><div data-ui-view class=\"col-xs-12 content\"></div></div>"
            })
                .state('adult', {
                abstract: true,
                url: '/szuloknek',
                controller: ['$rootScope', 'isAdult', function ($rootScope, isAdult) {
                        $rootScope['adult'] = isAdult;
                    }],
                resolve: {
                    isAdult: function () {
                        return true;
                    }
                },
                template: "<div class=\"row\"><div data-ui-view class=\"col-xs-12 content\"></div></div>"
            })
                .state('home.articles', {
                templateUrl: 'app/Home/index.html',
                url: '',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "home-page",
                    pageClass: ""
                },
                meta: {
                    title: 'Yelon',
                    titleSuffix: '',
                    'og:title': 'Yelon'
                }
            })
                .state('adult.articles', {
                templateUrl: 'app/Home/index.html',
                url: '',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "home-page",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Szülőknek',
                    'og:title': 'Szülőknek'
                }
            })
                .state('home.gallery', {
                url: 'galeria/{id}/{slug}',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "home-page",
                    pageClass: ""
                },
                meta: {
                    title: 'Galéria',
                    'og:title': 'Galéria'
                }
            })
                .state('adult.gallery', {
                url: '/galeria/{id}/{slug}',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "home-page",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Galéria',
                    'og:title': 'Galéria'
                }
            })
                .state('home.tag', {
                url: 'cimke/{tag}',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "tag-page"
                },
                meta: {
                    title: 'Címke',
                    'og:title': 'Címke'
                }
            })
                .state('adult.tag', {
                url: '/cimke/{tag}',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                data: {
                    pageId: "tag-page"
                },
                meta: {
                    title: 'Címke',
                    'og:title': 'Címke'
                }
            })
                .state('home.faq', {
                url: 'gyakori-kerdesek',
                templateUrl: 'app/Faq/index.html',
                controller: 'FaqController as faqCtrl',
                data: {
                    pageId: "faq-page"
                },
                meta: {
                    title: 'Gyakori kérdések',
                    'og:title': 'Gyakori kérdések',
                    description: 'Lehet, hogy már más is feltette ugyanezt a kérdést? Nézd meg, melyek a leggyakoribb kérdések a chaten!'
                }
            })
                .state('home.pages', {
                url: 'oldalak',
                templateUrl: 'app/Pages/index.html',
                controller: 'PagesController as pagesCtrl',
                data: {
                    pageId: "fix-pages"
                },
                meta: {
                    title: 'Rólunk',
                    'og:title': 'Rólunk'
                }
            })
                .state('adult.pages', {
                url: '/oldalak',
                templateUrl: 'app/Pages/index.html',
                controller: 'PagesController as pagesCtrl',
                data: {
                    pageId: "fix-pages",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Rólunk',
                    'og:title': 'Rólunk'
                }
            })
                .state('home.article', {
                url: 'cikkek/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: ""
                },
                meta: {
                    title: 'Cikk',
                    'og:title': 'Cikk'
                }
            })
                .state('adult.article', {
                url: '/cikkek/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Cikk',
                    'og:title': 'Cikk'
                }
            })
                .state('home.quiz', {
                url: 'kviz/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: ""
                },
                meta: {
                    title: 'Kvíz',
                    'og:title': 'Kvíz'
                }
            })
                .state('adult.quiz', {
                url: '/kviz/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Kvíz',
                    'og:title': 'Kvíz'
                }
            })
                .state('home.video', {
                url: 'video/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: ""
                },
                meta: {
                    title: 'Videó',
                    'og:title': 'Videó'
                }
            })
                .state('adult.video', {
                url: '/video/{id}/{slug}',
                templateUrl: 'app/Article/display.html',
                controller: 'ArticleController as articleCtrl',
                data: {
                    pageId: "article-page",
                    pageClass: "adult"
                },
                meta: {
                    title: 'Videó',
                    'og:title': 'Videó'
                }
            })
                .state('otherwise', {
                url: "*path",
                template: 'Ez az oldal nem létezik.',
                controller: [
                    '$state', '$scope', '$location',
                    function ($state, $scope, $location) {
                        if ($location.path() === '/szülőknek') {
                            $state.go('adult.articles');
                        }
                        $scope['goHome'] = function () {
                            $state.go('home.articles');
                        };
                    }
                ],
                meta: {
                    title: 'Nem létező oldal',
                    'og:title': 'Nem létező oldal'
                }
            });
            $locationProvider.html5Mode(true);
        }
    ]);
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('ArticleController', ['ApiFactory', 'spinnerService', 'facebookService', 'SafeStorage', '$stateParams', '$rootScope', 'ngMeta', '$sce', '$window', '$location', ArticleController]);
    function ArticleController(ApiFactory, spinnerService, facebookService, storage, $stateParams, $rootScope, ngMeta, $sce, $window, $location) {
        var vm = this;
        vm.settings = [];
        vm.article = null;
        vm.rateArticle = rateArticle;
        vm.getStyle = getStyle;
        vm.getBgColorStyle = getBgColorStyle;
        vm.onClickTagItem = onClickTagItem;
        vm.getVotedClass = getVotedClass;
        spinnerService.incrementLoading();
        ApiFactory.getFunction('articles/' + $stateParams['id']).then(function (data) {
            vm.article = data;
            // Workaround for Safari's insufficient date parsing capabilities...
            if (typeof vm.article.published_at === 'string') {
                var dateParts = vm.article.published_at.split(/[^0-9]/);
                vm.article.publishedDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]), Number(dateParts[3]), Number(dateParts[4]), Number(dateParts[5]));
            }
            else {
                vm.article.publishedDate = new Date;
            }
            var cover = vm.article.cropped_images.filter(function (img) {
                return img.type === "cover";
            });
            var ages = vm.article.attributes_values.map(function (attr) {
                if (attr.attribute_id === 2) {
                    return attr.title;
                }
            });
            var themes = vm.article.attributes_values.filter(function (attr) { return attr.attribute_id === 1; }).map(function (attr) { return attr.title; });
            var genders = vm.article.attributes_values.filter(function (attr) { return attr.attribute_id === 3; }).map(function (attr) { return attr.title; });
            vm.article.age = 18;
            ages.forEach(function (age) {
                if (typeof age !== "undefined" && age < vm.article.age) {
                    vm.article.age = age;
                }
            });
            vm.coverImage = cover.length > 0 ? cover[0].url : '';
            if (vm.article.cropped_images.length > 0) {
                for (var _i = 0, _a = vm.article.cropped_images; _i < _a.length; _i++) {
                    var image = _a[_i];
                    if (image.type === 'tile1') {
                        ngMeta.setTag('og:image', image.url);
                        ngMeta.setTag('og:image:width', 200);
                        ngMeta.setTag('og:image:height', 200);
                        break;
                    }
                }
            }
            vm.article.paragraphs.forEach(function (paragraph) {
                if (paragraph.type === "iframe2") {
                    paragraph.content = $sce.trustAsHtml(paragraph.content);
                }
                else if (paragraph.type === "text") {
                    var _content = paragraph.content.replace(/-br-/g, "</br>");
                    paragraph.content = _content;
                }
            });
            ngMeta.setTitle(vm.article.title);
            ngMeta.setTag('description', vm.article.teaser);
            //ngMeta.setTag('author', vm.article.user.name);
            ngMeta.setTag('og:title', vm.article.title);
            ngMeta.setTag('og:type', 'article');
            $window.ga('send', 'pageview', {
                'dimension1': vm.article.main_type === 'adult' ? 'Szülőknek' : '10-18',
                'dimension2': vm.article.type,
                'dimension3': vm.article.age,
                'dimension4': genders.join(),
                'dimension5': themes.join()
            });
        }).finally(function () {
            spinnerService.decrementLoading();
            facebookService.update();
        });
        function getBgColorStyle() {
            return {
                'background-color': vm.article.cover_color
            };
        }
        function getStyle() {
            return {
                'background-image': 'url(' + vm.coverImage + ')'
            };
        }
        function rateArticle(isUpvote) {
            if (!storage.get('article_rated_' + $stateParams['id'])) {
                spinnerService.incrementLoading();
                ApiFactory.postFunction('articles/' + $stateParams['id'] + '/ratings', { 'like': isUpvote }).then(function () {
                    storage.set('article_rated_' + $stateParams['id'], true);
                    if (isUpvote) {
                        vm.article.ratings_up++;
                    }
                    else {
                        vm.article.ratings_down++;
                    }
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
        }
        function getVotedClass() {
            return (!storage.get('article_rated_' + $stateParams['id'])) ? '' : 'voted';
        }
        ;
        function onClickTagItem(item) {
            $rootScope.isSearchVisible = true;
            $rootScope.selectedTagItemTitle = item.title;
        }
    }
})();
(function () {
    'use strict';
    angular.module('Yelon')
        .controller('ChatController', ['$interval', '$uibModal', 'socketFactory', 'ApiFactory', 'chatPrefix', 'SafeStorage', "$rootScope", "$sce", "$scope", ChatController]);
    function ChatController($interval, $uibModal, socketFactory, ApiFactory, chatPrefix, storage, $rootScope, $sce, $scope) {
        var vm = this;
        vm.chatOpened = false;
        vm.clientId = null;
        vm.session = null;
        vm.isOpenNow = false;
        vm.onlineHelpers = 0;
        vm.isEnded = false;
        vm.messages = [];
        vm.rating = 3;
        vm.isConnected = false;
        vm.keyPressed = keyPressed;
        vm.textPasted = textPasted;
        vm.inputChanged = inputChanged;
        vm.sendMessage = sendMessage;
        vm.rateConversation = rateConversation;
        vm.messageClicked = messageClicked;
        vm.closeConversation = closeConversation;
        vm.openEmojiPicker = openEmojiPicker;
        vm.onAddEmojiIcon = onAddEmojiIcon;
        vm.emojiIconText = ":smile:";
        vm.messageInput = "";
        vm.messageText = "";
        vm.onKeyDown = onKeyDown;
        vm.isEnableInput = isEnableInput;
        vm.isShowEmojiPicker = false;
        vm.picFileUrl = null;
        ApiFactory.getFunction('settings').then(parseBusinessHours);
        $interval(setIsOpen, 30000);
        var prevInput = "";
        var socket = socketFactory({
            prefix: '',
            ioSocket: io.connect(chatPrefix)
        });
        socket.on('connect', initChat);
        function initChat() {
            vm.isConnected = true;
            vm.chatToken = storage.get('chatToken');
            if (vm.chatToken === null) {
                socket.emit('token:generate');
            }
            else {
                socket.emit('token:validate', vm.chatToken);
            }
            socket.on('token:generated', saveUser);
            socket.on('token:validated', saveUser);
            socket.on('chat:clients', function (data) {
                vm.onlineHelpers = data.count.helpers;
            });
            socket.on('disconnect', function () {
                vm.isConnected = false;
                vm.clientId = null;
                vm.chatToken = null;
                vm.messages = [];
                socket.removeAllListeners();
                socket.on('connect', initChat);
            });
            socket.on('chat:start', chatStarted);
            socket.on('chat:message', messageReceived);
            socket.on('chat:typing', function (data) {
                if (data.client.id !== vm.clientId) {
                    vm.isTypingReceived = data.typing;
                }
            });
            socket.on('chat:end', function (data) {
                /*vm.messages.push({
                    client: 'sessionclosed',
                    message: "Értékeléshez kérlek kattints erre az üzenetre",
                    time: new Date()
                });*/
                vm.isEnded = true;
                //delete vm.session;
                vm.isTypingReceived = false;
            });
            socket.on('chat:archive', archiveConversation);
            socket.on('chat:announcement', function (data) {
                vm.messages.push({
                    client: 'system',
                    message: data.message,
                    time: new Date()
                });
            });
        }
        function saveUser(data) {
            vm.clientId = data.id;
            vm.chatToken = data.token;
            storage.set('chatToken', data.token);
            vm.isConnected = true;
        }
        function onKeyDown($event) {
            if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter" || $event.keyIdentifier === "Enter") && $event.ctrlKey === false && $event.shiftKey === false) {
                $event.preventDefault();
                this.isEnterKey = true;
            }
        }
        function keyPressed($event) {
            this.textareaInput = $event.target;
            if (this.isEnterKey) {
                $event.preventDefault();
                this.sendMessage();
                vm.messageText = "";
                $event.target.innerHTML = "";
                delete this.isEnterKey;
            }
            else {
                var replaceText = $event.target.innerHTML.replace(/(<img src=".[\/a-z0-9\."\s=]+)(:[a-z_]+:)(">)/g, '$2');
                replaceText = replaceText.replace(/<br>/g, "\n");
                replaceText = replaceText.replace(/&nbsp;/g, "");
                replaceText = replaceText.replace(/&lt;/g, "<");
                replaceText = replaceText.replace(/&gt;/g, ">");
                vm.messageInput = replaceText;
                vm.inputChanged();
            }
        }
        function textPasted($event) {
            $event.preventDefault();
            document.execCommand("insertHTML", false, $event.clipboardData.getData('text/plain'));
        }
        function inputChanged() {
            //handle typing here
            if (prevInput.trim().length > 0 && vm.messageInput.trim().length === 0) {
                emitTyping(false);
            }
            else if (prevInput.trim().length === 0 && vm.messageInput.trim().length > 0) {
                emitTyping(true);
            }
            prevInput = vm.messageInput;
        }
        function sendMessage() {
            if (vm.messageInput.trim().length > 0) {
                if (vm.session === null) {
                    socket.emit('chat:create', vm.messageInput);
                }
                else {
                    socket.emit('chat:message', vm.session.id, vm.messageInput);
                }
                if (this.textareaInput) {
                    this.textareaInput.innerHTML = "";
                }
                vm.messageText = "";
                vm.messageInput = "";
                prevInput = "";
                emitTyping(false);
            }
        }
        function closeConversation() {
            $uibModal.open({
                windowClass: "custom-modal",
                templateUrl: '/app/layouts/popupMessage.html',
                controller: ['$uibModalInstance', '$scope', 'article', function ($uibModalInstance, $scope, article) {
                        $scope.article = article;
                        $scope.confirm = function () {
                            socket.emit('chat:close', vm.session.id);
                            $uibModalInstance.close();
                        };
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                    }],
                resolve: {
                    article: function () {
                        return {
                            popup_message: 'Biztosan befejezed a beszélgetést? Ezzel a beszélgetés törlődik.'
                        };
                    }
                }
            });
        }
        function rateConversation() {
            if (vm.session !== null) {
                socket.emit('chat:rating', vm.session.id, vm.rating);
                delete vm.session;
            }
        }
        function chatStarted(data) {
            vm.session = data;
            vm.session.id = data.session_id;
            vm.session.messages.forEach(function (msg) {
                vm.messages.push({
                    client: msg.client,
                    message: msg.message,
                    time: new Date(msg.time)
                });
            });
            $rootScope.isChatOpened = true;
            vm.chatOpened = true;
        }
        function messageClicked(msg) {
            msg.showTime = !msg.showTime;
            if (msg.client === 'sessionclosed') {
                vm.isEnded = true;
            }
        }
        function archiveConversation(data) {
            vm.session = null;
            vm.messageInput = "";
            $rootScope.isChatOpened = false;
            vm.chatOpened = false;
            vm.isEnded = false;
            vm.messages = [];
            vm.isTypingReceived = false;
        }
        function messageReceived(data) {
            if (vm.session !== null) {
                vm.messages.push({
                    client: data.client,
                    message: $sce.trustAsHtml(data.message),
                    time: new Date()
                });
                $rootScope.countMessage++;
            }
        }
        function emitTyping(val) {
            if (vm.session !== null) {
                socket.emit('chat:typing', vm.session.id, val);
            }
        }
        function parseBusinessHours(data) {
            vm.dayNames = [
                "Hétfő",
                "Kedd",
                "Szerda",
                "Csütörtök",
                "Péntek",
                "Szombat",
                "Vasárnap"
            ];
            vm.businessHours = data.business_hours;
            var sunday = vm.businessHours.shift();
            vm.businessHours.push(sunday);
            if (data.closed_image !== null) {
                ApiFactory.getFunction('images/' + data.closed_image).then(function (result) {
                    vm.picFileUrl = result.full_url;
                });
            }
            setIsOpen();
        }
        function setIsOpen() {
            vm.now = new Date();
            var today = vm.businessHours[(vm.now.getDay() + 6) % 7];
            var fromTime = today.from.split(':');
            var toTime = today.to.split(':');
            var from = new Date().setHours(fromTime[0], fromTime[1]);
            var to = new Date().setHours(toTime[0], toTime[1]);
            vm.isOpenNow = vm.now >= from && vm.now <= to;
        }
        function openEmojiPicker() {
            vm.isShowEmojiPicker = (!vm.isShowEmojiPicker);
        }
        function onAddEmojiIcon(text) {
            var searchName = text.replace(/[:]/g, "");
            var selectedEmojiObj = $rootScope.emojis[searchName];
            var imgHtml = $sce.trustAsHtml("<img src=\"./assets/img/emojis/" + selectedEmojiObj.unicode + ".svg\" alt=\"" + text + "\">");
            vm.messageText = vm.messageInput;
            if (vm.messageText !== "") {
                vm.messageText = vm.messageText.replace(/([:][a-z_]+[:])/g, function () {
                    var texts = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        texts[_i - 0] = arguments[_i];
                    }
                    searchName = texts[0].replace(/[:]/g, "");
                    selectedEmojiObj = $rootScope.emojis[searchName];
                    texts[1] = $sce.trustAsHtml("<img src=\"./assets/img/emojis/" + selectedEmojiObj.unicode + ".svg\" alt=\"" + texts[0] + "\">");
                    return texts[1];
                });
                vm.messageText = vm.messageText.replace(/<br>/g, "\n");
            }
            vm.messageInput += text + ' ';
            vm.messageText += imgHtml;
            vm.isShowEmojiPicker = false;
        }
        function isEnableInput() {
            return (!((vm.session === null && !vm.isOpenNow) || !vm.isConnected));
        }
    }
})();
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            onAddEmojiIcon: '<'
        },
        controller: controllerCtrl,
        templateUrl: 'app/Emojipicker/template.html',
        transclude: true
    };
    function controllerCtrl() {
        var _this = this;
        this.$onInit = function () { return (_this.emoji_icons = [":grinning:", ":grimacing:", ":grin:", ":joy:", ":smiley:", ":smile:", ":sweat_smile:", ":laughing:", ":innocent:", ":wink:", ":blush:", ":relaxed:", ":yum:", ":relieved:", ":heart_eyes:", ":kissing_heart:", ":kissing:", ":kissing_smiling_eyes:", ":kissing_closed_eyes:", ":stuck_out_tongue_winking_eye:", ":stuck_out_tongue_closed_eyes:", ":stuck_out_tongue:", ":sunglasses:", ":no_mouth:", ":pensive:", ":confused:", ":cry:"]); };
    }
    angular
        .module('Yelon')
        .component('emojiPicker', component);
})(Yelon || (Yelon = {}));
(function () {
    'use strict';
    angular.module('Yelon').controller('FaqController', ['ApiFactory', 'spinnerService', '$anchorScroll', '$rootScope', '$window', FaqController]);
    function FaqController(ApiFactory, spinnerService, $anchorScroll, $rootScope, $window) {
        var vm = this;
        vm.faqCategories = [];
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            vm.faqCategories = data;
            var dict = data.filter(function (item) { return item.title === "szótár"; })[0];
            if (dict && $rootScope.goToDict) {
                window.setTimeout(function () {
                    vm.onClickFaqTheme(dict.id);
                    delete $rootScope.goToDict;
                }, 10);
            }
        }).finally(function () { return spinnerService.decrementLoading(); });
        vm.onClickFaqTheme = function (id) {
            $anchorScroll('question-' + id);
        };
        vm.onClickQuestion = function (item) {
            item.opened = (item.opened === undefined) ? true : undefined;
            if (item.opened) {
                var category = vm.faqCategories.filter(function (cat) { return cat.id === item.faq_category_id; })[0].title;
                if (category.toLowerCase() === 'szótár') {
                    $window.ga('send', 'event', 'Szótár', 'kattintás', item.question);
                }
                else {
                    $window.ga('send', 'event', 'GYIK', category, item.question);
                }
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('HomeController', ['ApiFactory', 'spinnerService', '$state', '$rootScope', '$stateParams', '$window', HomeController]);
    function HomeController(ApiFactory, spinnerService, $state, $rootScope, $stateParams, $window) {
        var vm = this;
        vm.tiles = [];
        vm.articles = [];
        vm.tag = false;
        vm.gaTimer = false;
        var setFixTiles = function () {
            ApiFactory.getFunction('articles?type=fix').then(function (data) {
                var fixTiles;
                if (data.length > 0) {
                    var typePrefix_1 = $rootScope.adult ? 1 : 0;
                    fixTiles = data.filter(function (item) { return item.popup_message.match(typePrefix_1) !== null; });
                    fixTiles.forEach(function (item) {
                        var page = item.popup_message.replace(/[0-9]-/, "");
                        item.id = (page === "rolunk" || page === "segitoink") ? "faq" : "app";
                        item.route = "^.pages";
                        item.routeParams = {
                            '#': page
                        };
                        if (page === "rolunk" || page === "segitoink") {
                            item.color = '#fff';
                            item.block = 1;
                            item.row = 0;
                            item.col = 0;
                            item.tileType = "tile2";
                            if (item.cropped_images.length > 0) {
                                item.background = "#fff url(" + item.cropped_images[1].url + ") 0 0/cover";
                            }
                            else {
                                item.background = (typePrefix_1 === 0) ? "#f4cd49" : "#26808d";
                            }
                            item.backTileBg = (typePrefix_1 === 0) ? "#f4cd49" : "#26808d";
                        }
                        else {
                            item.color = '#000';
                            item.block = 0;
                            item.row = 0;
                            item.col = 0;
                            item.tileType = "tile3";
                            if (item.cropped_images.length > 0) {
                                item.background = "#fff url(" + item.cropped_images[2].url + ") 0 0/cover";
                            }
                            else {
                                item.background = (typePrefix_1 === 0) ? "#26d2cb" : "#26ac8b";
                            }
                            item.backTileBg = (typePrefix_1 === 0) ? "#26d2cb" : "#26ac8b";
                        }
                        item.backTileColor = "#fff";
                        item.isFixed = true;
                        item.flipDisabled = false;
                        item.show_title = false;
                    });
                }
                vm.fixTiles = fixTiles;
            });
        };
        /*vm.fixTiles = $rootScope.adult ? [
            {
                route: '^.pages',
                routeParams: {'#': 'rolunk'},
                id: 'faq',
                title: 'Minden olyan felnőttnek, aki felelősséget érez a gyerekekért.',
                teaser: 'Az egyszerűség kedvéért szülői oldalnak hívjuk, de a tartalom összeállításánál minden olyan felnőttre gondoltunk, aki 0-18 éves gyerekért felelős.',
                background: '#fff url(assets/img/faq-adult-tile.png) 0 0/cover',
                backTileBg: '#f4cd49',
                backTileColor: '#fff',
                color: 'transparent',
                tileType: 'tile2',
                block: 1,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }, {
                id: 'app',
                route: "^.pages",
                routeParams: {'#': 'applikacio'},
                title: 'Yelon mobil alkalmazás 10-18 éveseknek',
                teaser: `A Yelon applikációjával chatelni és böngészni is lehet. Elérhető iOS és Android telefonokra.`,
                background: '#fff url(assets/img/app-adult-bg.png) 0 0/cover',
                backTileBg: '#26d2cb',
                backTileColor: '#fff',
                color: 'black',
                tileType: 'tile3',
                block: 0,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }] : [
            {
                route: '^.faq',
                id: 'faq',
                title: 'Gyakori kérdések',
                teaser: 'Lehet, hogy már más is feltette ugyanezt a kérdést? Nézd meg melyek a leggyakoribb kérdések a chaten!',
                background: '#fff url(assets/img/faq-tile.png) 0 0/cover',
                backTileBg: '#f4cd49',
                backTileColor: '#fff',
                color: 'transparent',
                tileType: 'tile2',
                block: 1,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }, {
                id: 'app',
                route: "^.pages",
                routeParams: {'#': 'applikacio'},
                title: 'Töltsd le az alkalmazást!',
                teaser: `Töltsd le a Yelon alkalmazást Android és IOS telefonodra.
                    Chatelj segítőinkkel! Kérdezz bátran! Test, lélek, szexualitás... itt nincs tabu! Cikkeinket akár offline is elérheted.`,
                background: '#fff url(assets/img/app-bg.png) 0 0/cover',
                backTileBg: '#26d2cb',
                backTileColor: '#fff',
                color: 'black',
                tileType: 'tile3',
                block: 0,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }];*/
        $rootScope.filtersEnabled = true;
        //check if adult
        if ($state.current.name === "adult") {
            $rootScope.adult = true;
        }
        //check if there is a tag
        if ($state.current.name === "tag") {
            vm.tag = $stateParams['tag'];
        }
        //get (filtered) articles
        vm.getArticles = function () {
            setFixTiles();
            spinnerService.incrementLoading();
            var parameters = $rootScope.adult ? "?main_type=adult" : "?main_type=web";
            if ($rootScope.selectedAttributes.length > 0) {
                $rootScope.selectedAttributes.forEach(function (val) {
                    parameters += "&attributes[]=";
                    parameters += val;
                });
            }
            ApiFactory.getFunction('articles' + parameters).then(function (data) {
                var articles = data.filter(function (item) { return item.type !== "fix"; });
                vm.articles = articles;
            })
                .finally(function () {
                spinnerService.decrementLoading();
            });
        };
        vm.sendAnalytics = function () {
            if ($rootScope.filterCounter['theme'] && $rootScope.selectedFilterNames['theme'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Téma', $rootScope.selectedFilterNames['theme'].join());
            }
            if ($rootScope.filterCounter['gender'] && $rootScope.selectedFilterNames['gender'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Nem', $rootScope.selectedFilterNames['gender'].join());
            }
            if ($rootScope.filterCounter['age'] && $rootScope.selectedFilterNames['age'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Kor', $rootScope.getFilteredAgeTitle());
            }
        };
        //init
        vm.getArticles();
        $rootScope.$watch('selectedAttributes', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                vm.getArticles();
                if (vm.gaTimer) {
                    clearTimeout(vm.gaTimer);
                }
                vm.gaTimer = setTimeout(function () { return vm.sendAnalytics(); }, 15000);
            }
        }, true);
        $rootScope.$watch('adult', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                vm.getArticles();
                if (vm.gaTimer) {
                    clearTimeout(vm.gaTimer);
                }
                vm.gaTimer = setTimeout(function () { return vm.sendAnalytics(); }, 15000);
            }
        }, true);
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('PagesController', ['ApiFactory', 'spinnerService', '$location', '$rootScope', 'ngMeta', PagesController]);
    function PagesController(ApiFactory, spinnerService, $location, $rootScope, ngMeta) {
        var vm = this;
        vm.settings = [];
        vm.tabs = {
            'rolunk': 0,
            'segitoink': 1,
            'applikacio': 2,
        };
        vm.activeTab = vm.tabs[$location.hash()];
        vm.setActiveTab = function (hash) {
            $location.hash(hash);
        };
        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.settings = data;
            if (vm.settings.about_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.about_image).then(function (data) {
                    vm.settings.about_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            if (vm.settings.helpers_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.helpers_image).then(function (data) {
                    vm.settings.helpers_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            if (vm.settings.application_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.application_image).then(function (data) {
                    vm.settings.application_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            // duplicated, cause the original implementation doesn't trigger a page/state refresh when switching between
            // adult and non-adult state, and watching the rootscope variable would be overkill
            if (vm.settings.adult_about_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_about_image).then(function (data) {
                    vm.settings.adult_about_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            if (vm.settings.adult_helpers_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_helpers_image).then(function (data) {
                    vm.settings.adult_helpers_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
            if (vm.settings.adult_application_image !== null) {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('images/' + vm.settings.adult_application_image).then(function (data) {
                    vm.settings.adult_application_image = data.full_url;
                }).finally(function () {
                    spinnerService.decrementLoading();
                });
            }
        }).finally(function () {
            spinnerService.decrementLoading();
        });
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('SearchController', ['ApiFactory', 'spinnerService', '$timeout', '$rootScope', "$uibModal", '$window', SearchController]);
    function SearchController(ApiFactory, spinnerService, $timeout, $rootScope, $uibModal, $window) {
        var vm = this;
        vm.queryStr = "";
        vm.searchTimeout = null;
        vm.isSearchVisible = false;
        vm.queryChanged = queryChanged;
        vm.onClickResultItem = onClickResultItem;
        function queryChanged() {
            var mainType = $rootScope.adult ? "adult" : "web";
            if (vm.searchTimeout) {
                $timeout.cancel(vm.searchTimeout);
            }
            vm.searchTimeout = $timeout(function () {
                if (vm.queryStr.trim().length > 0) {
                    $window.ga('send', 'event', 'Keresés', 'Keresés', vm.queryStr);
                    ApiFactory.getFunction('search?main_type=' + mainType + '&q=' + vm.queryStr).then(function (data) {
                        if (!vm.isSearchVisible) {
                            vm.isSearchVisible = true;
                        }
                        vm.results = data;
                    });
                }
            }, 2000);
        }
        function onClickResultItem(event, result) {
            if (result.popup_message) {
                event.preventDefault();
                openModal(result);
            }
        }
        ;
        function openModal(data) {
            $uibModal.open({
                windowClass: "custom-modal",
                templateUrl: '/app/layouts/popupMessage.html',
                controller: ['$uibModalInstance', '$scope', '$state', 'article', function ($uibModalInstance, $scope, $state, article) {
                        $scope.article = article;
                        $scope.confirm = function () {
                            window.location.href = article.url;
                        };
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                    }],
                resolve: {
                    article: function () {
                        return data;
                    }
                }
            });
        }
        ;
        $rootScope.$watch("selectedTagItemTitle", function (newValue) {
            if (newValue !== undefined) {
                vm.queryStr = newValue;
                vm.queryChanged();
            }
        });
        $rootScope.$watch("isSearchVisible", function (newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                vm.queryStr = "";
                delete $rootScope.selectedTagItemTitle;
                delete vm.results;
            }
        });
    }
})();
(function () {
    'use strict';
    // Key codes
    var keys = {
        enter: 13,
        esc: 27,
        left: 37,
        right: 39
    };
    angular.module('Yelon').directive('imageGallery', ['$timeout', '$q', 'spinnerService', 'ApiFactory', '$state', '$rootScope', 'facebookService', 'SafeStorage',
        function ($timeout, $q, spinnerService, ApiFactory, $state, $rootScope, facebookService, storage) {
            return {
                replace: true,
                transclude: false,
                restrict: 'AE',
                scope: {
                    images: '=',
                    methods: '=?',
                    sum: '=',
                    conf: '=?',
                    thumbnails: '=?',
                    onOpen: '&?',
                    onClose: '&?' // function
                },
                templateUrl: '/app/layouts/imageGallery.html',
                link: function (scope, elem, attr) {
                    //scope.thumbnails = false
                    //scope.bubbles = false;
                    scope.isShowInfoBox = true;
                    /*
                     *	Operational functions
                     **/
                    // Show gallery loader
                    scope.showLoader = function () {
                        scope.imgLoading = true;
                    };
                    // Hide gallery loader
                    scope.hideLoader = function () {
                        scope.imgLoading = false;
                    };
                    // Image load complete promise
                    scope.loadImg = function (imgObj) {
                        // Return rejected promise
                        // if not image object received
                        if (!imgObj) {
                            return $q.reject();
                        }
                        var deferred = $q.defer();
                        // Show loder
                        if (!imgObj.hasOwnProperty('cached')) {
                            scope.showLoader();
                        }
                        // Process image
                        var img = new Image();
                        img.src = imgObj.url;
                        img.onload = function () {
                            // Hide loder
                            if (!imgObj.hasOwnProperty('cached')) {
                                scope.hideLoader();
                            }
                            // Cache image
                            if (!imgObj.hasOwnProperty('cached')) {
                            }
                            imgObj.cached = true;
                            return deferred.resolve(imgObj);
                        };
                        return deferred.promise;
                    };
                    scope.setActiveImg = function (imgObj) {
                        // Get images move direction
                        if (scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) === (scope.images.length - 1) ||
                            (scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) <= 0 &&
                                scope.images.indexOf(scope.activeImg) - scope.images.indexOf(imgObj) !== -(scope.images.length - 1))) {
                            scope.imgMoveDirection = 'forward';
                        }
                        else {
                            scope.imgMoveDirection = 'backward';
                        }
                        // Load image
                        scope.loadImg(imgObj).then(function (imgObj) {
                            scope.activeImg = imgObj;
                            if (storage.get('gallery_rated_' + scope.activeImg.id)) {
                                scope.voted = true;
                            }
                        });
                    };
                    /***************************************************/
                    /*
                     *	Gallery settings
                     **/
                    // Modify scope models
                    scope.images = (typeof scope.images !== "undefined") ? scope.images : [];
                    scope.methods = (typeof scope.methods !== "undefined") ? scope.methods : {};
                    scope.conf = (typeof scope.conf !== "undefined") ? scope.conf : {};
                    scope.onOpen = (typeof scope.onOpen !== "undefined") ? scope.onOpen : angular.noop;
                    scope.onClose = (typeof scope.onClose !== "undefined") ? scope.onClose : angular.noop;
                    // If images populate dynamically, reset gallery
                    var imagesFirstWatch = true;
                    scope.$watch('images', function () {
                        if (imagesFirstWatch) {
                            imagesFirstWatch = false;
                        }
                        else if (scope.images.length) {
                            scope.setActiveImg(scope.images[scope.activeImageIndex || 0]);
                        }
                    });
                    // Watch index of visible/active image
                    // If index changes, make sure to load/change image
                    var activeImageIndexFirstWatch = true;
                    scope.$watch('activeImageIndex', function (newImgIndex) {
                        if (activeImageIndexFirstWatch) {
                            activeImageIndexFirstWatch = false;
                        }
                        else if (scope.images.length) {
                            scope.setActiveImg(scope.images[newImgIndex]);
                        }
                    });
                    $rootScope.$watch('shareCount', function (newcount) {
                        scope.shareCount = newcount;
                    });
                    scope.sharePage = $rootScope.sharePage;
                    /***************************************************/
                    /*
                     *	Methods
                     **/
                    // Open gallery modal
                    scope.methods.open = function (imgIndex) {
                        // Open modal from an index if one passed
                        scope.activeImageIndex = (imgIndex) ? imgIndex : 0;
                        scope.opened = true;
                        // call open event after transition
                        $timeout(function () {
                            scope.isShowInfoBox = true;
                            scope.onOpen();
                            facebookService.update();
                        }, 300);
                    };
                    // Close gallery modal
                    scope.methods.close = function () {
                        scope.opened = false; // Model closed
                        // set overflow hidden to body
                        angular.element(document.body).removeClass('body-overflow-hidden');
                        // call close event after transition
                        $timeout(function () {
                            scope.onClose();
                            scope.activeImageIndex = 0; // Reset index
                            $state.go('^.articles');
                        }, 300);
                    };
                    // Change image to next
                    scope.methods.next = function () {
                        if (scope.activeImageIndex === (scope.images.length - 1)) {
                            if (!scope.isShowInfoBox) {
                                scope.isShowInfoBox = true;
                                return;
                            }
                            else {
                                scope.isShowInfoBox = false;
                            }
                            scope.activeImageIndex = 0;
                        }
                        else if (scope.activeImageIndex === 0 && scope.isShowInfoBox) {
                            scope.isShowInfoBox = false;
                        }
                        else {
                            scope.activeImageIndex = scope.activeImageIndex + 1;
                        }
                    };
                    // Change image to prev
                    scope.methods.prev = function () {
                        var imgLength = scope.images.length - 1;
                        if (scope.activeImageIndex === 0) {
                            if (scope.isShowInfoBox) {
                                scope.isShowInfoBox = false;
                                return;
                            }
                            scope.activeImageIndex = imgLength;
                        }
                        else {
                            if (scope.activeImageIndex === imgLength && !scope.isShowInfoBox) {
                                scope.isShowInfoBox = true;
                                return;
                            }
                            else if (scope.activeImageIndex === imgLength && scope.isShowInfoBox) {
                                scope.isShowInfoBox = false;
                            }
                            scope.activeImageIndex--;
                        }
                    };
                    // Close gallery on background click
                    scope.backgroundClose = function (e) {
                        var noCloseClasses = [
                            'galleria-image',
                            'destroy-icons-container',
                            'ext-url',
                            'close',
                            'next',
                            'prev',
                            'galleria-bubble'
                        ];
                        // check if clicked element has a class that
                        // belongs to `noCloseClasses`
                        for (var i = 0; i < e.target.classList.length; i++) {
                            if (noCloseClasses.indexOf(e.target.classList[i]) !== -1) {
                                break;
                            }
                            else {
                                scope.methods.close();
                            }
                        }
                    };
                    scope.onClickVoteBtn = function (isUpvote) {
                        if (storage.get('gallery_rated_' + scope.activeImg.id)) {
                            return;
                        }
                        else {
                            spinnerService.incrementLoading();
                            ApiFactory.postFunction('articles/' + scope.activeImg.id + '/ratings', {
                                'like': isUpvote,
                                'type': 'galery'
                            }).then(function () {
                                storage.set('gallery_rated_' + scope.activeImg.id, true);
                                if (isUpvote) {
                                    scope.activeImg.ratings_up++;
                                }
                                else {
                                    scope.activeImg.ratings_down++;
                                }
                                scope.voted = true;
                            }).finally(function () {
                                spinnerService.decrementLoading();
                            });
                        }
                    };
                    scope.getVotedClass = function () {
                        return (scope.voted) ? "voted" : "";
                    };
                    /***************************************************/
                    /*
                     *	User interactions
                     **/
                    // Key events
                    angular.element(document).bind('keyup', function (event) {
                        if (event.which === keys.right || event.which === keys.enter) {
                            $timeout(function () {
                                scope.methods.next();
                            });
                        }
                        else if (event.which === keys.left) {
                            $timeout(function () {
                                scope.methods.prev();
                            });
                        }
                        else if (event.which === keys.esc) {
                            $timeout(function () {
                                scope.methods.close();
                            });
                        }
                    });
                    // Swipe events
                    /*if(window.Hammer){
                     var hammerElem = new Hammer(elem[0]);
                     hammerElem.on('swiperight', function(ev){
                     $timeout(function(){
                     scope.methods.prev();
                     });
                     });
                     hammerElem.on('swipeleft', function(ev){
                     $timeout(function(){
                     scope.methods.next();
                     });
                     });
                     hammerElem.on('doubletap', function(ev){

                     $timeout(function(){
                     scope.methods.close();
                     });
                     });
                     }*/
                }
            };
        }
    ]);
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("ngMobileClick", [mobileClick]);
    function mobileClick() {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                scope.$apply(attrs["ngMobileClick"]);
            });
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("spinner", ['spinnerService', spinner]);
    function spinner(spinnerService) {
        return {
            restrict: 'A',
            templateUrl: '/app/layouts/spinner.html',
            scope: false,
            replace: true,
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope['showSpinner'] = spinnerService.showSpinner;
                    $scope.$watch(function () {
                        return spinnerService.showSpinner;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            if (newValue) {
                                $scope['showSpinner'] = spinnerService.showSpinner;
                            }
                            else {
                                $timeout(function () {
                                    $scope['showSpinner'] = spinnerService.showSpinner;
                                }, 500);
                            }
                        }
                    }, true);
                }]
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("tiles", tiles);
    function tiles() {
        return {
            restrict: "A",
            scope: {
                articles: '=',
                fixTiles: '=?',
                isEmbedded: '=?'
            },
            templateUrl: '/app/layouts/tiles.html',
            controller: ['$rootScope', '$scope', '$state', '$stateParams', '$uibModal', 'ApiFactory', 'ngMeta', 'Slug', '$anchorScroll', 'spinnerService', '$window', '$location', function ($rootScope, $scope, $state, $stateParams, $uibModal, ApiFactory, ngMeta, Slug, $anchorScroll, spinnerService, $window, $location) {
                    $scope.flippedStatus = [];
                    $scope.sum = 0;
                    $scope.images = [];
                    $scope.methods = {};
                    $scope.fixTiles = typeof $scope.fixTiles === "undefined" ? [] : $scope.fixTiles;
                    $scope.teasersDisabled = typeof $scope.teasersDisabled === "undefined" ? false : true;
                    var artIdx = 0;
                    var blockIdx = 0;
                    $scope.blocks = [];
                    var blocks = [];
                    $scope.$watch("articles", function (newVal, oldVal) {
                        artIdx = 0;
                        blockIdx = 0;
                        $scope.blocks = [];
                        blocks = [];
                        while ((!$scope.isEmbedded && artIdx < $scope.articles.length) ||
                            $scope.isEmbedded && artIdx < $scope.articles.length && blockIdx < 3) {
                            blocks[blockIdx] = [
                                [null, null],
                                [null, null],
                                [null, null]
                            ];
                            //handle fix tiles here
                            if (Array.isArray($scope.fixTiles)) {
                                $scope.fixTiles.forEach(function (tile) {
                                    if (tile.block === blockIdx) {
                                        blocks[blockIdx][tile.row][tile.col] = tile;
                                    }
                                });
                            }
                            $scope.blocks[blockIdx] = [];
                            positionTiles(0, 0);
                            positionTiles(0, 1);
                            positionTiles(0, 2);
                            blockIdx++;
                        }
                        //check if gallery
                        if ($state.current.name === "home.gallery" || $state.current.name === "adult.gallery") {
                            var gallery = void 0, articleId = Number($stateParams['id']);
                            for (var _i = 0, _a = $scope.articles; _i < _a.length; _i++) {
                                var article = _a[_i];
                                if (article.id === articleId) {
                                    gallery = article;
                                    break;
                                }
                            }
                            if (gallery) {
                                $scope.openGallery(gallery);
                            }
                        }
                        else if ($state.current.name === 'home.articles' || $state.current.name === 'adult.articles') {
                            var checkSpinner_1 = function () {
                                if (spinnerService.showSpinner) {
                                    setTimeout(checkSpinner_1, 1000);
                                }
                                else if ($rootScope.tileAnchor !== null && $scope.articles.length > 0) {
                                    $anchorScroll($rootScope.tileAnchor);
                                    $rootScope.tileAnchor = null;
                                }
                            };
                            $scope.$evalAsync(function () { return setTimeout(checkSpinner_1, 1000); });
                        }
                    });
                    function positionTiles(posX, posY) {
                        if (posY > 2 || posX > 5 || artIdx === $scope.articles.length) {
                            return;
                        }
                        var currCell = blocks[blockIdx][posY][posX];
                        if (blocks[blockIdx][posY][posX] === null || typeof currCell === "object") {
                            var isFixed = typeof currCell === "object" && currCell !== null;
                            var allowedTypes = $scope.isEmbedded ? ['tile2', 'tile4'] : ['tile1', 'tile2', 'tile3', 'tile4'];
                            if (posX % 2 === 1 || posX + 1 >= blocks[blockIdx][posY].length || blocks[blockIdx][posY][posX + 1] !== null) {
                                //noinspection TypeScriptValidateTypes
                                allowedTypes = allowedTypes.filter(function (type) { return type !== 'tile2' && type !== 'tile4'; });
                            }
                            if (posY === 2) {
                                //noinspection TypeScriptValidateTypes
                                allowedTypes = allowedTypes.filter(function (type) { return type !== 'tile3' && type !== 'tile4'; });
                            }
                            var rnd = Math.random();
                            /**
                             * If all of the tiles can be used, the chances for each type to be chosen are the following:
                             * tile1: 15%
                             * tile2: 20%
                             * tile3: 20%
                             * tile4: 45%
                             */
                            var chosenIndex = 0;
                            if (allowedTypes.length === 4) {
                                chosenIndex = rnd < 0.15 ? 0 : (rnd < 0.35 ? 1 : (rnd < 0.55 ? 2 : 3));
                            }
                            else {
                                chosenIndex = Math.floor(rnd * allowedTypes.length);
                            }
                            var chosenType = isFixed ? currCell.tileType : allowedTypes[chosenIndex];
                            if (isFixed) {
                                $scope.blocks[blockIdx].push(currCell);
                            }
                            else {
                                //noinspection TypeScriptUnresolvedVariable
                                $scope.articles[artIdx].tileType = chosenType;
                                $scope.blocks[blockIdx].push($scope.articles[artIdx]);
                                artIdx++;
                                currCell = chosenType;
                            }
                            switch (chosenType) {
                                case 'tile2':
                                    blocks[blockIdx][posY][posX + 1] = false;
                                    break;
                                case 'tile3':
                                    blocks[blockIdx][posY + 1][posX] = false;
                                    break;
                                case 'tile4':
                                    blocks[blockIdx][posY + 1][posX] = false;
                                    blocks[blockIdx][posY + 1][posX + 1] = false;
                                    blocks[blockIdx][posY][posX + 1] = false;
                                    break;
                            }
                        }
                        positionTiles(posX + 1, posY);
                        return;
                    }
                    $scope.getAge = function (article) {
                        if (typeof article.attributes_values === "undefined") {
                            return;
                        }
                        var minAge = 100;
                        article.attributes_values.forEach(function (attr) {
                            if (attr.attribute_id === 2 && attr.title < minAge) {
                                minAge = attr.title;
                            }
                        });
                        return minAge;
                    };
                    $scope.getRowSpan = function (type) {
                        if (type === 'tile3' || type === 'tile4') {
                            return 2;
                        }
                        return 1;
                    };
                    $scope.getColSpan = function (type) {
                        if (type === 'tile2' || type === 'tile4') {
                            return 2;
                        }
                        return 1;
                    };
                    $scope.getHref = function (article) {
                        if (article.isFixed) {
                            return $state.href(article.route, article.routeParams);
                        }
                        else if (article.type === 'motto') {
                            return '';
                        }
                        else if (article.type === 'gallery') {
                            return $state.href("^.gallery", {
                                id: parseInt(article.id),
                                slug: Slug.slug(article.title)
                            });
                        }
                        else if (article.type === 'quiz') {
                            return $state.href('^.quiz', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        }
                        else if (article.type === 'video') {
                            return $state.href('^.video', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        }
                        else {
                            return $state.href('^.article', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        }
                    };
                    $scope.followTile = function (article) {
                        $rootScope.tileAnchor = 'tile' + article.id;
                        if (article.isFixed) {
                            return $state.go(article.route, article.routeParams);
                        }
                        else if (article.type === 'motto') {
                            return;
                        }
                        else if (typeof article.popup_message !== 'string' || article.popup_message.length === 0) {
                            if (article.type === 'gallery') {
                                return $scope.openGallery(article);
                            }
                            else if (article.type === 'quiz') {
                                return $state.go('^.quiz', {
                                    id: article.id,
                                    slug: Slug.slug(article.title)
                                });
                            }
                            else if (article.type === 'video') {
                                return $state.go('^.video', {
                                    id: article.id,
                                    slug: Slug.slug(article.title)
                                });
                            }
                            return $state.go('^.article', {
                                id: article.id,
                                slug: Slug.slug(article.title)
                            });
                        }
                        else {
                            var $parentScope_1 = $scope;
                            $uibModal.open({
                                windowClass: "custom-modal",
                                templateUrl: '/app/layouts/popupMessage.html',
                                controller: ['$uibModalInstance', '$scope', '$state', 'article', function ($uibModalInstance, $scope, $state, article) {
                                        $scope.article = article;
                                        $scope.confirm = function () {
                                            if (article.type === 'gallery') {
                                                $parentScope_1.openGallery(article);
                                            }
                                            else if (article.type === 'quiz') {
                                                $state.go('^.quiz', {
                                                    id: article.id,
                                                    slug: Slug.slug(article.title)
                                                });
                                            }
                                            else if (article.type === 'video') {
                                                $state.go('^.video', {
                                                    id: article.id,
                                                    slug: Slug.slug(article.title)
                                                });
                                            }
                                            else {
                                                $state.go('^.article', {
                                                    id: article.id,
                                                    slug: Slug.slug(article.title)
                                                });
                                            }
                                            $uibModalInstance.close();
                                        };
                                        $scope.close = function () {
                                            $uibModalInstance.close();
                                        };
                                    }],
                                resolve: {
                                    article: function () {
                                        return article;
                                    }
                                }
                            });
                        }
                    };
                    $scope.getTileStyle = function (article) {
                        if (article.isFixed) {
                            return {
                                'background': article.background,
                                'color': article.color
                            };
                        }
                        var tileImgUrl = [];
                        if (typeof article.cropped_images === "undefined" || article.cropped_images.length <= 1 ||
                            (tileImgUrl = article.cropped_images.filter(function (img) {
                                return img.type === article.tileType;
                            })).length === 0) {
                            return {
                                'background-color': article.color,
                                'background-image': '',
                                'background-size': 'cover',
                                'color': 'white'
                            };
                        }
                        return {
                            'background-color': article.color,
                            'background-image': 'url(' + tileImgUrl[0].url + ')',
                            'background-size': 'cover',
                            'color': 'black'
                        };
                    };
                    $scope.getBackTileStyle = function (article) {
                        if (article.isFixed) {
                            return {
                                'background': typeof article.backTileBg !== "undefined" ? article.backTileBg : article.background,
                                'background-image': 'none',
                                'color': typeof article.backTileColor !== "undefined" ? article.backTileColor : article.color
                            };
                        }
                        return {
                            'background-color': article.theme_color,
                            'background-image': '',
                            'background-size': ''
                        };
                    };
                    $scope.openGallery = function (article) {
                        $scope.images = [];
                        ApiFactory.getFunction('articles/' + article.id).then(function (data) {
                            $scope.images = [];
                            angular.forEach(data.paragraphs, function (val, key) {
                                if (val.type === 'image') {
                                    $state.go("^.gallery", {
                                        id: parseInt(article.id),
                                        slug: Slug.slug(article.title)
                                    }, {
                                        notify: false
                                    });
                                    $scope.images.push({
                                        id: article.id,
                                        url: val.image.full_url,
                                        href: val.title,
                                        title: val.content,
                                        teaser: data.teaser,
                                        article_title: data.title,
                                        ratings_up: data.ratings_up,
                                        ratings_down: data.ratings_down
                                    });
                                }
                            });
                            $scope.sum = $scope.images.length;
                            $scope.methods.open();
                            if (data.images.length > 0) {
                                var image = data.images[0];
                                ngMeta.setTag('og:image', image.full_url);
                                ngMeta.setTag('og:image:width', image.width);
                                ngMeta.setTag('og:image:height', image.height);
                            }
                            var ages = article.attributes_values.map(function (attr) {
                                if (attr.attribute_id === 2) {
                                    return attr.title;
                                }
                            });
                            var themes = article.attributes_values.filter(function (attr) { return attr.attribute_id === 1; }).map(function (attr) { return attr.title; });
                            var genders = article.attributes_values.filter(function (attr) { return attr.attribute_id === 3; }).map(function (attr) { return attr.title; });
                            article.age = 18;
                            ages.forEach(function (age) {
                                if (typeof age !== "undefined" && age < article.age) {
                                    article.age = age;
                                }
                            });
                            ngMeta.setTitle(data.title);
                            ngMeta.setTag('description', data.teaser);
                            //ngMeta.setTag('author', data.name);
                            ngMeta.setTag('og:title', data.title);
                            ngMeta.setTag('og:type', 'article');
                            $window.ga('set', 'page', $location.path());
                            $window.ga('send', 'pageview', {
                                'dimension1': article.main_type === 'adult' ? 'Szülőknek' : '10-18',
                                'dimension2': article.type,
                                'dimension3': article.age,
                                'dimension4': genders.join(),
                                'dimension5': themes.join()
                            });
                        });
                    };
                }],
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').factory('ApiFactory', ['$http', '$q', 'apiPrefix', '$rootScope', ApiFactory]);
    function ApiFactory($http, $q, apiPrefix, $rootScope) {
        return {
            getFunction: getFunction,
            postFunction: postFunction,
            patchFunction: patchFunction,
            deleteFunction: deleteFunction,
        };
        function getFunction(url) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }
            return $http.get(apiPrefix + url).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function postFunction(url, data, settings) {
            return $http.post(apiPrefix + url, data, settings).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function patchFunction(url, data, settings) {
            return $http.patch(apiPrefix + url, data, settings).then(success).catch(returnError);
            function success() {
                $rootScope.lastSave = new Date();
                return true;
            }
            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen módosítás, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function deleteFunction(url) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }
            return $http.delete(apiPrefix + url).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    console.error('Sikertelen törlés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        /**
         *
         * @param response
         * @returns {boolean} should the caller show the alert window?
         */
        function handleError(response) {
            if (response.status === 500) {
                return true;
            }
            //expired token
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    console.error('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }
            //other message
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }
            return true;
        }
    }
})();
(function () {
    'use strict';
    angular.module('Yelon')
        .filter('emojione', ['$sce', function ($sce) {
            return function (input) {
                input = $sce.valueOf(input);
                if (typeof input !== 'string') {
                    return input;
                }
                return emojione.unicodeToImage(input
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;"));
            };
        }]);
})();
(function () {
    'use strict';
    angular.module('Yelon').filter('iframeEmbedUrl', ['$sce', iframeEmbedUrl]);
    function iframeEmbedUrl($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url.replace(/^https?:\/\//, '//'));
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon')
        .filter('slugify', ['Slug', function (Slug) {
            return function (input) {
                if (typeof input !== 'string' || input.length === 0) {
                    return input;
                }
                return Slug.slug(input);
            };
        }]);
})();
(function () {
    'use strict';
    angular.module('Yelon').filter('toTrusted', ['$sce', toTrusted]);
    function toTrusted($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').filter('youtubeEmbedUrl', ['$sce', youtubeEmbedUrl]);
    function youtubeEmbedUrl($sce) {
        return function (videoId) {
            return $sce.trustAsResourceUrl('//www.youtube.com/embed/' + videoId);
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').service('facebookService', ['$rootScope', '$http', '$state', facebookService]);
    function facebookService($rootScope, $http, $state) {
        return {
            update: function () {
                return $http.get('https://graph.facebook.com/?id=' + $state.href($state.current.name, $state.params, { absolute: true }))
                    .then(function (result) {
                    if (typeof result.data === 'object' && typeof result.data.share === 'object') {
                        $rootScope.shareCount = result.data.share.share_count;
                    }
                    else {
                        $rootScope.shareCount = 0;
                    }
                })
                    .catch(function () {
                    $rootScope.shareCount = 0;
                });
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon')
        .service('Slug', ['$window', function ($window) {
            $window.slug.defaults.mode = 'rfc3986';
            return {
                slug: function (value) { return $window.slug(value); }
            };
        }]);
})();
(function () {
    'use strict';
    angular.module('Yelon').service('spinnerService', [spinnerService]);
    function spinnerService() {
        var currentlyLoading = 0;
        var showSpinner = false;
        return {
            incrementLoading: function () {
                currentlyLoading++;
                this.showSpinner = true;
            },
            decrementLoading: function () {
                currentlyLoading--;
                if (currentlyLoading === 0) {
                    this.showSpinner = false;
                }
            },
            showSpinner: showSpinner
        };
    }
})();
(function () {
    'use strict';
    /**
     * Fix for Safari not supporting localStorage in private browsing mode
     *
     * @license MIT
     * @link https://github.com/HackedByChinese/ng-idle/issues/111
     */
    angular.module('Yelon')
        .service('SafeStorage', ['$window', '$cookieStore', function ($window, $cookieStore) {
            var storage, methods;
            function fromJson(data) {
                try {
                    return angular.fromJson(data);
                }
                catch (err) {
                    return null;
                }
            }
            try {
                storage = $window.localStorage;
                methods = {
                    driver: 'localstorage',
                    set: function (key, value) { return storage.setItem(key, angular.toJson(value)); },
                    get: function (key) { return fromJson(storage.getItem(key)); },
                    remove: function (key) { return storage.removeItem(key); },
                    // backwards compatibility with localStorage API
                    setItem: function (key, value) { return storage.setItem(key, value); },
                    getItem: function (key) { return storage.getItem(key); },
                    removeItem: function (key) { return storage.removeItem(key); }
                };
            }
            catch (err) {
                methods = {
                    driver: 'cookie',
                    set: function (key, value) { return $cookieStore.put(key, angular.toJson(value)); },
                    get: function (key) { return fromJson($cookieStore.get(key)); },
                    remove: function (key) { return $cookieStore.remove(key); },
                    // backwards compatibility with localStorage API
                    setItem: function (key, value) { return $cookieStore.put(key, value); },
                    getItem: function (key) { return $cookieStore.get(key); },
                    removeItem: function (key) { return $cookieStore.remove(key); }
                };
            }
            return methods;
        }]);
})();
