(function () {
    'use strict';

    angular.module('Yelon').filter('iframeEmbedUrl', ['$sce', iframeEmbedUrl]);

    function iframeEmbedUrl($sce) {
        return function(url) {
            return $sce.trustAsResourceUrl(url.replace(/^https?:\/\//,'//'));
        };
    }

})();
