(function () {
    'use strict';

    angular.module('Yelon').service('facebookService', ['$rootScope', '$http', '$state', facebookService]);

    function facebookService($rootScope, $http: angular.IHttpService, $state) {
        return {
            update: function () {
                return $http.get('https://graph.facebook.com/?id=' + $state.href($state.current.name, $state.params, { absolute: true }))
                    .then((result: any) => {
                        if (typeof result.data === 'object' && typeof result.data.share === 'object') {
                            $rootScope.shareCount = result.data.share.share_count;
                        } else {
                            $rootScope.shareCount = 0;
                        }
                    })
                    .catch(() => {
                        $rootScope.shareCount = 0;
                    });
            }
        };
    }
})();
