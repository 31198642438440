(function () {
    'use strict';

    angular.module('Yelon').controller('ArticleController', ['ApiFactory', 'spinnerService', 'facebookService', 'SafeStorage', '$stateParams', '$rootScope', 'ngMeta', '$sce', '$window', '$location', ArticleController]);

    function ArticleController(ApiFactory, spinnerService, facebookService, storage, $stateParams: angular.ui.IStateParamsService, $rootScope, ngMeta, $sce, $window, $location) {
        let vm: any = this;
        vm.settings = [];
        vm.article = null;

        vm.rateArticle = rateArticle;
        vm.getStyle = getStyle;
        vm.getBgColorStyle = getBgColorStyle;
        vm.onClickTagItem = onClickTagItem;
        vm.getVotedClass = getVotedClass;

        spinnerService.incrementLoading();
        ApiFactory.getFunction('articles/' + $stateParams['id']).then(function (data) {
            vm.article = data;
            // Workaround for Safari's insufficient date parsing capabilities...
            if (typeof vm.article.published_at === 'string') {
                let dateParts = vm.article.published_at.split(/[^0-9]/);
                vm.article.publishedDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]), Number(dateParts[3]), Number(dateParts[4]), Number(dateParts[5]));
            } else {
                vm.article.publishedDate = new Date;
            }
            let cover = vm.article.cropped_images.filter(img => {
                return img.type === "cover";
            });
            let ages = vm.article.attributes_values.map(attr => {
                if (attr.attribute_id === 2) {
                    return attr.title;
                }
            });
            let themes = vm.article.attributes_values.filter(attr => attr.attribute_id === 1).map(attr => attr.title);
            let genders = vm.article.attributes_values.filter(attr => attr.attribute_id === 3).map(attr => attr.title);
            vm.article.age = 18;
            ages.forEach(age => {
                if (typeof age !== "undefined" && age < vm.article.age) {
                    vm.article.age = age;
                }
            });
            vm.coverImage = cover.length > 0 ? cover[0].url : '';

            if (vm.article.cropped_images.length > 0) {
                for (let image of vm.article.cropped_images) {
                    if (image.type === 'tile1') {
                        ngMeta.setTag('og:image', image.url);
                        ngMeta.setTag('og:image:width', 200);
                        ngMeta.setTag('og:image:height', 200);
                        break;
                    }
                }
            }

            vm.article.paragraphs.forEach((paragraph) => {
                if (paragraph.type === "iframe2") {
                    paragraph.content = $sce.trustAsHtml(paragraph.content);
                } else if (paragraph.type === "text") {
                    let _content = paragraph.content.replace(/-br-/g,"</br>");

                    paragraph.content = _content;
                }
            });

            ngMeta.setTitle(vm.article.title);
            ngMeta.setTag('description', vm.article.teaser);
            //ngMeta.setTag('author', vm.article.user.name);
            ngMeta.setTag('og:title', vm.article.title);
            ngMeta.setTag('og:type', 'article');

            $window.ga('send', 'pageview', {
                'dimension1': vm.article.main_type === 'adult' ? 'Szülőknek' : '10-18',
                'dimension2': vm.article.type,
                'dimension3': vm.article.age,
                'dimension4': genders.join(),
                'dimension5': themes.join()
            });
        }).finally(function () {
            spinnerService.decrementLoading();
            facebookService.update();
        });

        function getBgColorStyle() {
            return {
                'background-color': vm.article.cover_color
            };
        }

        function getStyle() {
            return {
                'background-image': 'url(' + vm.coverImage + ')'
            };
        }

        function rateArticle(isUpvote: boolean) {
            if (!storage.get('article_rated_' + $stateParams['id'])) {
                spinnerService.incrementLoading();
                ApiFactory.postFunction('articles/' + $stateParams['id'] + '/ratings', { 'like': isUpvote }).then(() => {
                    storage.set('article_rated_' + $stateParams['id'], true);
                    if (isUpvote) {
                        vm.article.ratings_up++;
                    } else {
                        vm.article.ratings_down++;
                    }
                }).finally(() => {
                    spinnerService.decrementLoading();
                });
            }
        }

        function getVotedClass() {
            return (!storage.get('article_rated_' + $stateParams['id'])) ? '' : 'voted';
        };

        function onClickTagItem(item: any) {
            $rootScope.isSearchVisible = true;
            $rootScope.selectedTagItemTitle = item.title;
        }
    }
})();
