(function () {
    'use strict';

    angular.module('Yelon').controller('HomeController', ['ApiFactory', 'spinnerService', '$state', '$rootScope', '$stateParams', '$window', HomeController]);

    function HomeController(ApiFactory, spinnerService, $state, $rootScope, $stateParams: angular.ui.IStateParamsService, $window) {
        let vm: any = this;
        vm.tiles = [];
        vm.articles = [];
        vm.tag = false;
        vm.gaTimer = false;

        var setFixTiles = function() {
            ApiFactory.getFunction('articles?type=fix').then(data => {
                let fixTiles;

                if (data.length > 0) {
                    let typePrefix = $rootScope.adult ? 1 : 0;

                    fixTiles = data.filter(item => item.popup_message.match(typePrefix) !== null);

                    fixTiles.forEach(item => {
                        let page = item.popup_message.replace(/[0-9]-/, "");

                        item.id = (page === "rolunk" || page === "segitoink") ? "faq" : "app";
                        item.route = "^.pages";

                        item.routeParams = {
                            '#': page
                        };

                        if (page === "rolunk" || page === "segitoink") {
                            item.color = '#fff';
                            item.block = 1;
                            item.row = 0;
                            item.col = 0;
                            item.tileType = "tile2";

                            if (item.cropped_images.length > 0) {
                                item.background = `#fff url(${item.cropped_images[1].url}) 0 0/cover`;
                            } else {
                                item.background = (typePrefix === 0) ? "#f4cd49" : "#26808d";
                            }

                            item.backTileBg = (typePrefix === 0) ? "#f4cd49" : "#26808d";
                        } else {
                            item.color = '#000';
                            item.block = 0;
                            item.row = 0;
                            item.col = 0;
                            item.tileType = "tile3";

                            if (item.cropped_images.length > 0) {
                                item.background = `#fff url(${item.cropped_images[2].url}) 0 0/cover`;
                            } else {
                                item.background = (typePrefix === 0) ? "#26d2cb" : "#26ac8b";
                            }

                            item.backTileBg = (typePrefix === 0) ? "#26d2cb" : "#26ac8b";
                        }

                        item.backTileColor = "#fff";

                        item.isFixed = true;
                        item.flipDisabled = false;
                        item.show_title = false;
                    });
                }

                vm.fixTiles = fixTiles;
            });
        };

        /*vm.fixTiles = $rootScope.adult ? [
            {
                route: '^.pages',
                routeParams: {'#': 'rolunk'},
                id: 'faq',
                title: 'Minden olyan felnőttnek, aki felelősséget érez a gyerekekért.',
                teaser: 'Az egyszerűség kedvéért szülői oldalnak hívjuk, de a tartalom összeállításánál minden olyan felnőttre gondoltunk, aki 0-18 éves gyerekért felelős.',
                background: '#fff url(assets/img/faq-adult-tile.png) 0 0/cover',
                backTileBg: '#f4cd49',
                backTileColor: '#fff',
                color: 'transparent',
                tileType: 'tile2',
                block: 1,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }, {
                id: 'app',
                route: "^.pages",
                routeParams: {'#': 'applikacio'},
                title: 'Yelon mobil alkalmazás 10-18 éveseknek',
                teaser: `A Yelon applikációjával chatelni és böngészni is lehet. Elérhető iOS és Android telefonokra.`,
                background: '#fff url(assets/img/app-adult-bg.png) 0 0/cover',
                backTileBg: '#26d2cb',
                backTileColor: '#fff',
                color: 'black',
                tileType: 'tile3',
                block: 0,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }] : [
            {
                route: '^.faq',
                id: 'faq',
                title: 'Gyakori kérdések',
                teaser: 'Lehet, hogy már más is feltette ugyanezt a kérdést? Nézd meg melyek a leggyakoribb kérdések a chaten!',
                background: '#fff url(assets/img/faq-tile.png) 0 0/cover',
                backTileBg: '#f4cd49',
                backTileColor: '#fff',
                color: 'transparent',
                tileType: 'tile2',
                block: 1,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }, {
                id: 'app',
                route: "^.pages",
                routeParams: {'#': 'applikacio'},
                title: 'Töltsd le az alkalmazást!',
                teaser: `Töltsd le a Yelon alkalmazást Android és IOS telefonodra. 
                    Chatelj segítőinkkel! Kérdezz bátran! Test, lélek, szexualitás... itt nincs tabu! Cikkeinket akár offline is elérheted.`,
                background: '#fff url(assets/img/app-bg.png) 0 0/cover',
                backTileBg: '#26d2cb',
                backTileColor: '#fff',
                color: 'black',
                tileType: 'tile3',
                block: 0,
                row: 0,
                col: 0,
                isFixed: true,
                flipDisabled: false
            }];*/
        $rootScope.filtersEnabled = true;

        //check if adult
        if ($state.current.name === "adult") {
            $rootScope.adult = true;
        }

        //check if there is a tag
        if ($state.current.name === "tag") {
            vm.tag = $stateParams['tag'];
        }

        //get (filtered) articles
        vm.getArticles = function () {
            setFixTiles();

            spinnerService.incrementLoading();

            let parameters = $rootScope.adult ? "?main_type=adult" : "?main_type=web";

            if ($rootScope.selectedAttributes.length > 0) {
                $rootScope.selectedAttributes.forEach(val => {
                    parameters += "&attributes[]=";
                    parameters += val;
                });
            }

            ApiFactory.getFunction('articles' + parameters).then(data => {
                    let articles = data.filter(item => item.type !== "fix");

                    vm.articles = articles;
                })
                .finally(function () {
                    spinnerService.decrementLoading();
                });
        };

        vm.sendAnalytics = () => {
            if ($rootScope.filterCounter['theme'] && $rootScope.selectedFilterNames['theme'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Téma', $rootScope.selectedFilterNames['theme'].join());
            }

            if ($rootScope.filterCounter['gender'] && $rootScope.selectedFilterNames['gender'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Nem', $rootScope.selectedFilterNames['gender'].join());
            }

            if ($rootScope.filterCounter['age'] && $rootScope.selectedFilterNames['age'].length > 0) {
                $window.ga('send', 'event', 'Szűrés', 'Kor', $rootScope.getFilteredAgeTitle());
            }
        };

        //init
        vm.getArticles();
        $rootScope.$watch('selectedAttributes', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                vm.getArticles();

                if (vm.gaTimer) {
                    clearTimeout(vm.gaTimer);
                }

                vm.gaTimer = setTimeout(() => vm.sendAnalytics(), 15000);
            }
        }, true);
        $rootScope.$watch('adult', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                vm.getArticles();

                if (vm.gaTimer) {
                    clearTimeout(vm.gaTimer);
                }

                vm.gaTimer = setTimeout(() => vm.sendAnalytics(), 15000);
            }
        }, true);
    }
})();
