/// <reference path="../../typings/tsd.d.ts"/>
declare var FB:any;

(function () {
    'use strict';

    angular.module('Yelon').run(['$rootScope', '$state', 'ApiFactory', 'ngMeta', '$window', '$location', 'gaTrackingId', '$http', 'fbAppId', function ($rootScope, $state: angular.ui.IStateService, ApiFactory, ngMeta, $window, $location, gaTrackingId, $http, fbAppId) {
        let defaultTitle;
        let notifyInterval;

        $rootScope.tileAnchor = null;
        $rootScope.countMessage = 0;

        window.onblur = function() {
            $rootScope.countMessage = 0;
            if (!defaultTitle) {
                defaultTitle = document.title;
            }
            notifyInterval = window.setInterval(() => {
                if ($rootScope.countMessage > 0) {
                    document.title = `(${$rootScope.countMessage}) ${defaultTitle}`;
                }
            }, 1000);
        };

        window.onfocus = function() {
            if (defaultTitle) {
                document.title = defaultTitle;
                window.clearInterval(notifyInterval);
                notifyInterval = undefined;
                $rootScope.countMessage = 0;
            }
        };

        $http.get("./app/emoji_strategy.json").then((result) => {
            $rootScope.emojis = result.data;
        }).catch(() => {
            console.warn("Hiányzik az emoji_strategy.json file");
        });

        $rootScope.menuItemActiveClass = "";
        $rootScope['isMenuVisible'] = false;
        $rootScope['cookieConsentJustAgreed'] = false;
        $rootScope['adult'] = false;
        $rootScope['filters'] = [[], [], []];
        $rootScope['shouldResetFilters'] = true;
        $rootScope.isShowAppDownloaderContent = true;

        $rootScope['selectedAttributes'] = [];
        $rootScope['selectedFilterNames'] = { 'theme': [], 'age': [], 'gender': [] };
        $rootScope['filterCounter'] = { theme: 0, age: 0, gender: 0 };
        $rootScope['toggleFilter'] = function (attrName, attrVal) {
            let idx = $rootScope.selectedAttributes.indexOf(attrVal.id);

            if (!attrVal.activeClass) {
                attrVal.activeClass = "active";
            } else {
                delete attrVal.activeClass;
            }

            if (idx > -1) {
                $rootScope.filterCounter[attrName]--;

                $rootScope.selectedAttributes.splice(idx, 1);
                if (attrName !== "age") {
                    $rootScope.selectedFilterNames[attrName].splice($rootScope.selectedFilterNames[attrName].indexOf(attrVal.title), 1);
                } else {
                    $rootScope.selectedFilterNames.age.forEach((item, index) => {
                        if (attrVal.title === item) {
                            $rootScope.selectedFilterNames.age[index] = null;
                        }
                    });
                }
            } else {
                $rootScope.filterCounter[attrName]++;

                let firstValue = Number($rootScope.selectedAttributes[0]);
                let lastValue = Number(attrVal.id);


                console.log(firstValue);
                console.log(lastValue);
                if (firstValue > lastValue) {
                    firstValue = lastValue;
                    lastValue = Number($rootScope.selectedAttributes[$rootScope.selectedAttributes.length - 1]);
                }

                if (attrName === "age" && firstValue !== undefined && firstValue < lastValue) {

                    let arrayMiddle = [];
                    let titles = [];

                    $rootScope.filters[1].forEach((item) => {
                        if (item.id >= firstValue && item.id <= lastValue) {
                            arrayMiddle.push(item.id);
                            titles.push(item.title);
                        }
                    });

                    $rootScope.selectedAttributes = arrayMiddle;
                    $rootScope.selectedFilterNames[attrName] = titles;
                } else {
                    $rootScope.selectedFilterNames[attrName].push(attrVal.title);
                    $rootScope.selectedAttributes.push(attrVal.id);
                }
            }

        };

        $rootScope['resetFilters'] = function () {

            $rootScope.filters.forEach(function (filter) {
                filter.forEach(function (item) {
                    if (item.activeClass) {
                        delete item.activeClass;
                    }
                });
            });

            for (let i in $rootScope.selectedFilters) {
                if ($rootScope.selectedFilters.hasOwnProperty(i)) {
                    $rootScope.selectedFilters[i] = [];
                }
            }

            $rootScope.selectedAttributes = [];
        };

        ApiFactory.getFunction('attributes').then(function (data) {
            $rootScope.themes = data[0];
            $rootScope.filters[0] = data[0] ? data[0].attributes_values : [];
            $rootScope.filters[1] = data[1] ? data[1].attributes_values : [];
            $rootScope.filters[2] = data[2] ? data[2].attributes_values : [];
            $rootScope.filters[3] = data[3] ? data[3].attributes_values : [];
        });

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if ((toState.name.substr(0, 4) === 'home' && fromState.name.substr(0, 4) !== 'home') ||
                (toState.name.substr(0, 5) === 'adult' && fromState.name.substr(0, 5) !== 'adult')
            ) {
                $rootScope.shouldResetFilters = true;
            }

            if (toState && toState.data) {
                $rootScope.pageId = toState.data.pageId;
                $rootScope.pageClass = toState.data.pageClass || '';

                if ($rootScope.pageId === "home-page" && $rootScope.shouldResetFilters) {
                    if ($rootScope.selectedAttributes.length > 0) {
                        $rootScope.filterCounter = { theme: 0, age: 0, gender: 0 };
                        $rootScope.selectedAttributes = [];
                        $rootScope.selectedFilterNames = {
                            'theme': [],
                            'age': [],
                            'gender': []
                        };
                    }

                    $rootScope.shouldResetFilters = false;
                }
            }

            $rootScope.menuItemActiveClass = "";
            $rootScope.isMenuVisible = false;
            $rootScope.isSearchVisible = false;
        });

        $rootScope.$on('$stateChangeSuccess', function () {
            ngMeta.init();
            var url: string = $state.href($state.current.name, $state.params, { absolute: true });
            ngMeta.setTag('url', url);

            $window.ga('set', 'page', $location.path());

            console.log($state.current.templateUrl);
            if ($state.current.templateUrl !== "app/Article/display.html") {
                console.log('GA pageview sent');
                $window.ga('send', 'pageview');
            }
        });

        $rootScope.onClickDictBtn = function(event: Event) {
            event.preventDefault();

            $rootScope.goToDict = true;
            $state.go("home.faq", null, {reload: true});
        };

        /*$rootScope.$watch("adult", function (newValue, oldValue) {
            if (newValue !== oldValue) {
                switch (newValue) {
                    case true:
                        $state.go("adult");
                        break;
                    default:
                        $state.go("home");
                        break;
                }
            }
        });*/

        $rootScope.getFilteredAgeTitle = function() {
            let length = $rootScope.selectedFilterNames.age.length;

            if (length === 0) {
                return "Kor";
            } else if (length === 1) {
                return $rootScope.selectedFilterNames.age[0];
            } else {
                let filter = $rootScope.selectedFilterNames.age.filter(item => item !== null);

                if (filter.length === 1) {
                    return filter[0];
                } else if (filter.length > 1) {
                    let firstParts = [];
                    let lastParts = [];
                    let result;
                    let isFirstPart: boolean = true;

                    $rootScope.selectedFilterNames.age.forEach((item, index, array) => {
                        if (item === null && isFirstPart) {
                            isFirstPart = false;
                        } else if (item !== null && isFirstPart) {
                            firstParts.push(item);
                        } else if (item !== null && !isFirstPart) {
                            lastParts.push(item);
                        }
                    });

                    if (firstParts.length === 0 && lastParts.length === 0) {
                        result = "Kor";
                    } else if (firstParts.length === 0) {

                        let first = lastParts[0];
                        let last = lastParts[lastParts.length - 1];

                        result = `${first}-${last}`;
                    } else if (lastParts.length === 0) {

                        let first = firstParts[0];
                        let last = firstParts[firstParts.length - 1];

                        result = `${first}-${last}`;
                    } else {

                        if (firstParts.length === 1 && lastParts.length === 1) {
                            result = `${firstParts[0]},${lastParts[0]}`;
                        } else if (firstParts.length === 1) {
                            result = `${firstParts[0]},${lastParts[0]}-${lastParts[lastParts.length - 1]}`;
                        } else if (lastParts.length === 1) {
                            result = `${firstParts[0]}-${firstParts[firstParts.length - 1]},${lastParts[0]}`;
                        } else {
                            result = `${firstParts[0]}-${firstParts[firstParts.length - 1]},${lastParts[0]}-${lastParts[lastParts.length - 1]}`;
                        }
                    }

                    return result;
                } else {
                    return "Kor";
                }
            }
        };

        $rootScope.sharePage = function () {
            if (typeof FB !== 'undefined') {
                FB.ui({
                    app_id: fbAppId,
                    method: 'share',
                    href: $state.href($state.current.name, $state.params, {absolute: true})
                });
            }
        };

        $rootScope.isShowSiteHelp = function (notAgreeYet: boolean) {
            let isShow: boolean = ($rootScope.cookieConsentJustAgreed && notAgreeYet) ? true : false;

            if (isShow) {
                $rootScope.reflectorAnimateClass = "reflector-animate";
            } else {
                delete $rootScope.reflectorAnimateClass;
            }

            return isShow;
        };

        $rootScope.onClickAppDownloaderClose = function () {
            $rootScope.isShowAppDownloaderContent = false;
        };

        $rootScope.reloadPage = function () {
            if ($state.current.name === 'home.articles' || $state.current.name === 'adult.articles') {
                $rootScope.shouldResetFilters = true;
                $state.reload();
            }
        };

        $rootScope.shareCount = 0;

        ngMeta.init();
        $window.ga('create', gaTrackingId, 'auto');

        let fbInited = false;
        $window.fbAsyncInit = function() {
            if (!fbInited && typeof FB !== 'undefined') {
                FB.init({
                    appId: fbAppId,
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v2.8'
                });

                fbInited = true;
            } else {
                setTimeout($window.fbAsyncInit, 2500);
            }
        };

        $window.fbAsyncInit();
    }]);

    angular.module('Yelon').config(['$mdIconProvider', function ($mdIconProvider) {
        $mdIconProvider.iconSet("avatar", 'icons/avatar-icons.svg', 128);
    }]);

    angular.module('Yelon').config(['markedProvider', function (markedProvider) {
        markedProvider.setOptions({ gfm: true });
        markedProvider.setRenderer({
            link: (href, title, text) =>
                '<a href="' + href + '"' + (title ? ' title="' + title + '"' : '') + ' target="_blank">' + text + '</a>',
            table: (header, body) =>
                '<table class="table table-striped"><thead>' + header + '</thead><tbody>' + body + '</tbody></table>'
        });
    }]);

    angular.module('Yelon').config(['fbAppId', 'ogImage', 'ogImageWidth', 'ogImageHeight', 'ngMetaProvider', function (fbAppId, ogImage, ogImageWidth, ogImageHeight, ngMetaProvider) {
        ngMetaProvider.useTitleSuffix(true);
        ngMetaProvider.setDefaultTitle('Főoldal');
        ngMetaProvider.setDefaultTitleSuffix(' - Yelon');
        ngMetaProvider.setDefaultTag('description', 'A Yelon szexedukációs weboldal és alkalmazás azért jött létre, hogy testről, lélekről és szexualitásról őszintén, tabuk nélkül tájékozódhass.');
        ngMetaProvider.setDefaultTag('og:title', 'Főoldal - Yelon');
        ngMetaProvider.setDefaultTag('og:description', 'A Yelon szexedukációs weboldal és alkalmazás azért jött létre, hogy testről, lélekről és szexualitásról őszintén, tabuk nélkül tájékozódhass.');
        ngMetaProvider.setDefaultTag('author', 'Yelon');
        ngMetaProvider.setDefaultTag('fb:app_id', fbAppId);
        ngMetaProvider.setDefaultTag('og:locale', 'hu_HU');
        ngMetaProvider.setDefaultTag('og:site_name', 'Yelon');
        ngMetaProvider.setDefaultTag('og:type', 'website');
        ngMetaProvider.setDefaultTag('og:image', ogImage);
        ngMetaProvider.setDefaultTag('og:image:width', ogImageWidth);
        ngMetaProvider.setDefaultTag('og:image:height', ogImageHeight);
    }]);
})();
