(function () {
    'use strict';

    angular.module('Yelon').service('spinnerService', [spinnerService]);

    function spinnerService() {
        let currentlyLoading:number = 0;
        let showSpinner:boolean = false;

        return {
            incrementLoading: function () {
                currentlyLoading++;
                this.showSpinner = true;
            },
            decrementLoading: function () {
                currentlyLoading--;
                if (currentlyLoading === 0) {
                    this.showSpinner = false;
                }
            },
            showSpinner: showSpinner
        };
    }
})();
